import { YesNoButtonGroup } from "raci-react-library";
import { FORM_NAME_DIRECT_DEBIT_OPT_IN } from "../../types";

export interface DirectDebitOptInProps {
  isPaymentMethodLocked?: Boolean;
}

export const DirectDebitOptIn: React.FC<DirectDebitOptInProps> = ({ isPaymentMethodLocked }) => {
  return (
    <YesNoButtonGroup
      id="direct-debit-opt-in"
      name={FORM_NAME_DIRECT_DEBIT_OPT_IN}
      label="Would you like to set up annual direct debit?"
      sublabel="This means we'll debit your account in future years. We'll send your renewal documents before payment is due so you can make changes."
      defaultValue={isPaymentMethodLocked ? false : undefined}
      disabled={!!isPaymentMethodLocked}
      formControlMargin="none"
    />
  );
};

export default DirectDebitOptIn;
