import { PolicyDetailsResponse, RatingResponse, RenewalRatingRequest } from "raci-policy-renewal-caravan-clientproxy";
import { AtomEffect, DefaultValue, atom } from "recoil";
import {
  ATOM_ENDORSEMENT,
  ATOM_FORM_COMPLETED,
  ATOM_POLICY_DETAILS,
  ATOM_POLICY_PAYMENT_STATE,
  ATOM_PREVIOUS_RATING_REQUEST,
  ATOM_RATING,
  ATOM_SESSION_ID,
  ATOM_SHOW_WESTPAC_RETRY_DIALOG,
} from "../constants";
import { EndorsePolicyResponse, PolicyPaymentState } from "./types";

const sessionStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const sessionIdAtom = atom<string>({
  key: ATOM_SESSION_ID,
  default: "",
});

export const policyPaymentStateAtom = atom<PolicyPaymentState>({
  key: ATOM_POLICY_PAYMENT_STATE,
  default: {
    isPaymentMethodLocked: false,
    isCreditCardSelected: false,
    isRealtimePayment: false,
  },
  effects: [sessionStorageEffect<PolicyPaymentState>(ATOM_POLICY_PAYMENT_STATE)],
});

export const westpacRetryDialogAtom = atom<boolean>({
  key: ATOM_SHOW_WESTPAC_RETRY_DIALOG,
  default: false,
  effects: [sessionStorageEffect<boolean>(ATOM_SHOW_WESTPAC_RETRY_DIALOG)],
});

export const ratingResponseAtom = atom<RatingResponse | undefined>({
  key: ATOM_RATING,
  default: undefined,
  effects: [sessionStorageEffect<RatingResponse | undefined>(ATOM_RATING)],
});

export const policyDetailsAtom = atom<PolicyDetailsResponse | undefined>({
  key: ATOM_POLICY_DETAILS,
  default: undefined,
  effects: [sessionStorageEffect<PolicyDetailsResponse | undefined>(ATOM_POLICY_DETAILS)],
});

export const formCompletedAtom = atom<boolean>({
  key: ATOM_FORM_COMPLETED,
  default: false,
  effects: [sessionStorageEffect<boolean>(ATOM_FORM_COMPLETED)],
});

export const endorsementAtom = atom<EndorsePolicyResponse | undefined>({
  key: ATOM_ENDORSEMENT,
  default: undefined,
  effects: [sessionStorageEffect<EndorsePolicyResponse | undefined>(ATOM_ENDORSEMENT)],
});

export const previousRatingRequestAtom = atom<RenewalRatingRequest | undefined>({
  key: ATOM_PREVIOUS_RATING_REQUEST,
  default: undefined,
  effects: [sessionStorageEffect<RenewalRatingRequest | undefined>(ATOM_PREVIOUS_RATING_REQUEST)],
});
