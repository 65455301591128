import { PolicyRenewalCaravanPocApiException } from "raci-policy-renewal-caravan-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST } from "raci-react-library";
import { lazy } from "react";
import { NavigateFunction } from "react-router-dom";
import Confirmation from "../../views/Confirmation";
import Payment from "../../views/Payment";
import PreForm from "../../views/PreForm";
import YourRenewal from "../../views/YourRenewal";
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

export enum PreFormRoute {
  PreForm = "",
}

export enum FormRoute {
  YourRenewal = "your-renewal",
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum ErrorRoute {
  CallUs = "call-us",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum RouteName {
  PreForm = "PreForm",
  YourRenewal = "Your renewal",
  Payment = "Pay your renewal",
  Confirmation = "Confirmation",
  CallUs = "Call us",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum RouteHeading {
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
}

const getUtilityRouteUrl = (route: ErrorRoute) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;

export const UTILITY_CALL_US_PAGE_URL = getUtilityRouteUrl(ErrorRoute.CallUs);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(ErrorRoute.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(ErrorRoute.SystemUnavailable);

export type AppRoute = PreFormRoute | FormRoute | ErrorRoute;
export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const getRouteUrl = (route?: AppRoute) => `${process.env.PUBLIC_URL}${route ? `/${route}` : ""}`;

export const PRE_FORM_ROUTE_KEY = "preform";

export const preFormRoutesInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.PreForm]: {
    key: PRE_FORM_ROUTE_KEY,
    path: getRouteUrl(PreFormRoute.PreForm),
    name: RouteName.PreForm,
    element: <PreForm />,
  },
};

export const formRoutesInfo: Record<FormRoute, RouteInformation> = {
  [FormRoute.YourRenewal]: {
    key: FormRoute.YourRenewal,
    path: getRouteUrl(FormRoute.YourRenewal),
    name: RouteName.YourRenewal,
    element: <YourRenewal />,
  },
  [FormRoute.Payment]: {
    key: FormRoute.Payment,
    path: getRouteUrl(FormRoute.Payment),
    name: RouteName.Payment,
    element: <Payment />,
  },
  [FormRoute.Confirmation]: {
    key: FormRoute.Confirmation,
    path: getRouteUrl(FormRoute.Confirmation),
    name: RouteName.Confirmation,
    element: <Confirmation />,
  },
};

export const errorRoutesInfo: Record<ErrorRoute, RouteInformation> = {
  [ErrorRoute.CallUs]: {
    key: ErrorRoute.CallUs,
    path: getRouteUrl(ErrorRoute.CallUs),
    name: RouteName.CallUs,
    // TODO: Implement this page
    element: <></>,
  },
  [ErrorRoute.SessionTimeout]: {
    key: ErrorRoute.SessionTimeout,
    path: getRouteUrl(ErrorRoute.SessionTimeout),
    name: RouteName.SessionTimeout,
    element: <SessionTimeout />,
    heading: RouteHeading.SessionTimeout,
  },
  [ErrorRoute.SystemUnavailable]: {
    key: ErrorRoute.SystemUnavailable,
    path: getRouteUrl(ErrorRoute.SystemUnavailable),
    name: RouteName.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: RouteHeading.SystemUnavailable,
  },
};

export const preFormRoutes = Object.values(preFormRoutesInfo);
export const formRoutes = Object.values(formRoutesInfo);
export const errorRoutes = Object.values(errorRoutesInfo);

export const allRoutes = [...preFormRoutes, ...formRoutes, ...errorRoutes];

const navigateToUtilityPage = (navigate: NavigateFunction, url: string, virtualPageUrl: string, title: string) => {
  navigate(url, { replace: true });
};

export const handleExceptionNavigation = (navigate: NavigateFunction, requestName: string, exception?: any) => {
  const statusCode = (exception as PolicyRenewalCaravanPocApiException)?.status;

  if (statusCode === HTTP_STATUS_CODE_BAD_REQUEST) {
    navigateToUtilityPage(
      navigate,
      UTILITY_CALL_US_PAGE_URL,
      "/renew-your-policy/motor/error/400",
      `Road block - request: ${requestName}`,
    );
  } else {
    navigateToUtilityPage(
      navigate,
      UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
      "/renew-your-policy/motor/error/500",
      `System unavailable - request: ${requestName}`,
    );
  }
};
