import { useLogger } from "raci-react-library";
import { useEffect, useState } from "react";

import { PaymentReferenceDataResponse } from "../../../../shared/atoms/types";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useCustomLogProperties from "../useCustomLogProperties";

/** Get the Westpac Publishable API key for Westpac iframe */
const useGetPaymentReferenceData = () => {
  const client = useBffApiClient();
  const [isError, setIsError] = useState(false);
  const [paymentReferenceData, setPaymentReferenceData] = useState<PaymentReferenceDataResponse>();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();

  useEffect(() => {
    let isCancelled = false;

    const getPaymentReferenceData = async () => {
      setIsError(false);

      try {
        const response = (await client.getPaymentReferenceData()).result;

        // @ts-ignore
        setPaymentReferenceData(response);
      } catch (exception) {
        setIsError(true);
        logException({
          location: "useGetPaymentReferenceData.getPaymentReferenceData",
          message: exception,
          customProperties: customLogProperties,
        });
      }
    };

    if (!isCancelled && !isError && !paymentReferenceData) {
      getPaymentReferenceData();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, paymentReferenceData, customLogProperties]);

  return { paymentReferenceData, isError };
};

export default useGetPaymentReferenceData;
