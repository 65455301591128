import RenderGuard from "../../shared/components/RenderGuard";
import { PaymentDetailsForm } from "./Form";
import usePaymentDetails from "./hooks/usePaymentDetails";

export const PaymentDetails: React.FC = () => {
  return (
    <RenderGuard>
      <PaymentDetailsInternal />
    </RenderGuard>
  );
};

const PaymentDetailsInternal: React.FC = () => {
  const formProps = usePaymentDetails();

  return <PaymentDetailsForm {...formProps} />;
};

export default PaymentDetails;
