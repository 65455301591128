import { Typography } from "@mui/material";
import { AccountSelection, AccountSelectionProps, PaymentFrequency, useBsbLookup } from "raci-react-library";

import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import styles from "./styles";

export interface PaymentSelectionProps extends Omit<AccountSelectionProps, "preambleTitle" | "preambleContent"> {}

export const PaymentSelection: React.FC<PaymentSelectionProps> = ({ paymentAccounts, paymentFrequency }) => {
  // TODO: Make this work
  // const lookupApiClient = useLookupServiceApiClient();
  const { onBsbLookup } = useBsbLookup({ apiClient: {} as LookupServiceApiClient });
  const hasAccounts =
    paymentAccounts && (paymentAccounts.bankAccounts.length > 0 || paymentAccounts.creditCards.length > 0);
  const preambleTitle = "Choose how to pay";
  const preambleText =
    paymentFrequency === PaymentFrequency.Annual
      ? "We'll direct debit this account annually when payment is due."
      : "We'll direct debit all future payments from this account.";
  const preambleContent = (
    <>
      <Typography variant="body2" gutterBottom sx={styles.PreambleBodyText}>
        {preambleText}
      </Typography>
      {hasAccounts && (
        <Typography variant="body2" gutterBottom sx={styles.PreambleBodyText}>
          If your credit card is expired, you'll need to add a new credit card.
        </Typography>
      )}
    </>
  );

  return (
    <AccountSelection
      //paymentAccounts={paymentAccounts}
      paymentFrequency={paymentFrequency}
      preambleTitle={preambleTitle}
      preambleContent={preambleContent}
      onBsbLookup={onBsbLookup}
    />
  );
};

export default PaymentSelection;
