import { Box, Grid, Typography } from "@mui/material";
import { Card, FinePrint } from "@racwa/react-components";
import { PaymentFrequency, PaymentMethod, RoadsideAssistanceCard } from "raci-react-library";
import { createId, formatCurrency } from "../../../../shared/utils";
import { styles } from "../../styles";
import BaseConfirmation from "../BaseConfirmation";

export interface DirectDebitPolicyPaidProps {
  frequency: PaymentFrequency.Annual | PaymentFrequency.Monthly;
  paymentMethod?: string;
  firstName: string;
  policyNumber: string;
  renewalDate: string;
  dueDate: string;
  total: number;
  showRoadside: boolean;
}

export const DirectDebitPolicyPaid: React.FC<DirectDebitPolicyPaidProps> = ({
  frequency,
  paymentMethod,
  firstName,
  policyNumber,
  renewalDate,
  dueDate,
  total,
  showRoadside,
}) => {
  const id = "direct-debit-policy-paid";

  const getCardTitle = () => {
    return frequency === PaymentFrequency.Annual ? "Annual payment" : "Monthly payment";
  };

  const getFrequency = () => {
    return frequency === PaymentFrequency.Annual ? "annually" : "monthly";
  };

  const getPaymentMethod = () => {
    if (paymentMethod) {
      return `Payment: ${paymentMethod === PaymentMethod.Card ? "Credit card" : paymentMethod}`;
    }

    return "";
  };

  const getContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph")}>
              <b>
                {`You've renewed your policy and have chosen to pay ${getFrequency()}. You'll receive an email with your renewal documents shortly.`}
              </b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card background="white" sx={styles.CardContainer}>
            <Grid container>
              <Grid item xs={8}>
                <Typography align="left" variant="h3" component="div" id={createId(id, "card-title")}>
                  {getCardTitle()}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="h3" component="div" id={createId(id, "card-amount")}>
                  {formatCurrency(total)}
                </Typography>
              </Grid>
            </Grid>

            <Box mt={1}>
              <FinePrint align="left" id={createId(id, "payment-method")}>
                {getPaymentMethod()}
              </FinePrint>
            </Box>

            <Box mt={1}>
              <FinePrint align="left" id={createId(id, "renewal-date")}>{`Policy renews on: ${renewalDate}`}</FinePrint>
            </Box>

            <Box mt={1}>
              {frequency === PaymentFrequency.Monthly && (
                <FinePrint align="left" id={createId(id, "payment-due")}>{`Next payment due: ${dueDate}`}</FinePrint>
              )}

              {frequency === PaymentFrequency.Annual && (
                <FinePrint
                  align="left"
                  id={createId(id, "debit-date")}
                >{`We'll debit your account on this date`}</FinePrint>
              )}
            </Box>

            {showRoadside && (
              <Box mt={3}>
                <RoadsideAssistanceCard />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <BaseConfirmation
      id={id}
      policyNumber={policyNumber}
      title={`You're all set, ${firstName}!`}
      content={getContent()}
    />
  );
};

export default DirectDebitPolicyPaid;
