import { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { formCompletedAtom, sessionIdAtom } from "../../atoms";

export const RenderGuard = ({ children }: { children: ReactNode }) => {
  const isFormComplete = useRecoilValue(formCompletedAtom);
  const hasSessionId = useRecoilValue(sessionIdAtom);

  const shouldRender = hasSessionId !== "" && !isFormComplete;

  return <>{shouldRender && children}</>;
};

export default RenderGuard;
