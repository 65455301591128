import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Container, styled } from "@mui/material";
import { theme } from "@racwa/react-components";
import { colors } from "@racwa/styles";

export const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    maxWidth: "600px",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const styles = {
  CardContainer: {
    margin: "0px",
    padding: "24px",
    borderColor: colors.dieselLight,
  },
  Container: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const iconStyles = {
  height: "101px",
  width: "100%",
  color: theme.palette.secondary.main,
} satisfies FontAwesomeIconProps["style"];
