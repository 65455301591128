import { Box, Grid } from "@mui/material";
import {
  DirectDebitAuthorisationCard,
  DisclaimerType,
  PayLaterOption,
  PaymentAuthorisationCard,
  PaymentFormDisclaimerDisplay,
  PaymentMethod,
} from "raci-react-library";
import { useRecoilValue } from "recoil";
import { policyDetailsAtom } from "../../../../shared/atoms";
import { PolicyPaymentState } from "../../../../shared/atoms/types";
import getDirectDebitAuthorisationDocumentsUrl from "../../../../shared/utils/getDirectDebitAuthorisationDocumentsUrl";
import { FORM_ACCEPTED_TERMS_DIRECT_DEBIT, FORM_ACCEPTED_TERMS_REALTIME, PaymentDetailsState } from "../../types";

interface CreditCardAuthorisationProps {
  paymentDetails: PaymentDetailsState;
  policyPaymentState?: PolicyPaymentState;
}

export const CreditCardAuthorisation: React.FC<CreditCardAuthorisationProps> = ({
  paymentDetails,
  policyPaymentState,
}) => {
  const policyDetails = useRecoilValue(policyDetailsAtom); // TODO - SPK-4687 - Use hook or atom or session state?
  const documentsUrl = getDirectDebitAuthorisationDocumentsUrl(policyDetails);

  const hasSelectedExistingCard =
    !paymentDetails.isAddingAccount &&
    (paymentDetails.existingAccountType === PaymentMethod.Card ||
      paymentDetails.existingAccountType === PaymentMethod.BankAccount);
  const isAddingCard =
    paymentDetails.isAddingAccount &&
    (paymentDetails.paymentMethod === PaymentMethod.Card || paymentDetails.paymentMethod === PaymentMethod.BankAccount);
  const isRealTimeCreditCardPayment = paymentDetails.payLaterOption === PayLaterOption.Card;
  const shouldRender =
    hasSelectedExistingCard || isAddingCard || isRealTimeCreditCardPayment || policyPaymentState?.isPaymentMethodLocked;

  return (
    <>
      {shouldRender && (
        <Grid item xs={12}>
          <Box mt={3}>
            {isRealTimeCreditCardPayment || policyPaymentState?.isPaymentMethodLocked ? (
              <>
                <PaymentAuthorisationCard />
                <PaymentFormDisclaimerDisplay
                  name={FORM_ACCEPTED_TERMS_REALTIME}
                  disclaimerType={DisclaimerType.CardPayment}
                />
              </>
            ) : (
              <>
                <DirectDebitAuthorisationCard ddrsaUrl={documentsUrl} pdsUrl={documentsUrl} />
                {/* Unused div added to prevent RHF confusing the states of the checkboxes in the same location */}
                <div />
                <PaymentFormDisclaimerDisplay
                  name={FORM_ACCEPTED_TERMS_DIRECT_DEBIT}
                  disclaimerType={DisclaimerType.DirectDebit}
                />
              </>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default CreditCardAuthorisation;
