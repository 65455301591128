import { RacwaStandardPageTemplateProps, theme } from "@racwa/react-components";
import { useLocation } from "react-router-dom";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const useStandardPage = (): RacwaStandardPageTemplateProps => {
  const commonPageProps = usePage();
  const location = useLocation();
  const { errorRoutes } = useRoutes();

  const currentErrorRoute = errorRoutes.find((route) => route.path.toLowerCase() === location.pathname.toLowerCase());

  const heading = currentErrorRoute?.heading ?? "";

  const useYellowHeading = false;

  return {
    heading,
    bannerTextColour: useYellowHeading ? theme.palette.primary.main : theme.palette.common.white,
    navBreadcrumbProps: commonPageProps.responsiveHeaderProps?.NavBreadcrumbsProps,
    ...commonPageProps,
  };
};

export default useStandardPage;
