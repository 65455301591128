import { RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { useSetBackdrop } from "raci-react-library";

const useRatingBackdrop = () => {
  const setBackdrop = useSetBackdrop();

  /**
   * If it is the first rating (ratingResponse is undefined),
   * set backdrop message to "Calculating...",
   * otherwise set it to "Recalculating..."
   * @param display display or hide rating backdrop
   * @param ratingResponse type of rating response determines message to display
   */
  const displayRatingBackdrop = (display: boolean, ratingResponse: RatingResponse | undefined) => {
    setBackdrop(display, ratingResponse ? "Recalculating..." : "Calculating...");
  };

  return displayRatingBackdrop;
};

export default useRatingBackdrop;
