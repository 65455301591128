import { RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { PaymentFrequency, currencyFormatter } from "raci-react-library";

export const getPremiumAmount = (paymentFrequency: PaymentFrequency, ratingResponse: RatingResponse) => {
  return paymentFrequency === PaymentFrequency.Annual
    ? ratingResponse.annualPremium?.total
    : ratingResponse.monthlyPremium?.instalment;
};

export const getFormattedPremiumAmount = (paymentFrequency: PaymentFrequency, ratingResponse: RatingResponse) =>
  currencyFormatter(getPremiumAmount(paymentFrequency, ratingResponse)!);
