import { Box, Grid, OutlinedInput, Typography } from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { RacwaFormLabel } from "@racwa/react-components";
import {
  BasicTooltip,
  KEYBOARD_CODE_ENTER,
  THOUSAND_SEPARATOR_THRESHOLD,
  currencyFormatter,
  formatIdString,
} from "raci-react-library";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  FORM_NAME_SELECTED_AGREED_VALUE,
  FORM_NAME_SELECTED_AGREED_VALUE_CHANGED,
  YourRenewalState,
} from "../../types";
import styles from "./styles";

export interface AgreedValueProps {
  min: number;
  max: number;
  current: number;
  onChangeValues: (value: YourRenewalState) => void;
}

/**
 * Agreed Value only allows whole numbers to be displayed and selected by the member,
 * but policies in Shield can have a Sum Insured value with cents.
 * Rounding causes minor premium discrepancy which is unacceptable for the Business.
 * Sum Insured is truncated in the BFF and will use existing Sum Insured value on
 * the policy if Agreed Value is undefined in rating/endorsement requests.
 * @param agreedValueChanged
 * @param agreedValue
 * @returns undefined if Agreed Value has not been manually changed otherwise Agreed Value
 */
export const getAgreedValue = (agreedValueChanged: boolean, agreedValue?: number) =>
  agreedValueChanged ? agreedValue : undefined;

export const AgreedValue: React.FC<AgreedValueProps> = ({ min, max, current, onChangeValues }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<YourRenewalState>();

  const label = "Agreed value";
  const id = "agreed-value";
  const inputId = formatIdString(id + "input");
  const tooltipId = formatIdString(id + "tooltip");
  const [agreedValue, setAgreedValue] = useState<number | undefined>(current);

  const tooltipProps = {
    showDialog: false,
    title: "Agreed value",
    message:
      "The agreed value is the amount we agree to insure your caravan for. The agreed value includes GST, registration, on-road costs and accessories fitted to your caravan.",
  };

  const formattedMin = currencyFormatter(min);
  const formattedMax = currencyFormatter(max);

  const setAgreedValueChanged = () => {
    if (agreedValue !== undefined && agreedValue !== current && agreedValue <= max && agreedValue >= min) {
      setValue(FORM_NAME_SELECTED_AGREED_VALUE_CHANGED, true);
    }
  };

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        defaultValue={current}
        aria-label={id}
        name={FORM_NAME_SELECTED_AGREED_VALUE}
        rules={{
          required: {
            value: true,
            message: "Required",
          },
          min: min,
          max: max,
        }}
        render={({ field: { onChange, onBlur, ref, value }, fieldState: { error } }) => (
          <Box mt={0} mb={1}>
            <Grid container sx={styles.GridContainer}>
              <Grid item xs={8}>
                <RacwaFormLabel id={formatIdString(id + "label")} htmlFor={inputId} error={!!error}>
                  {label}
                </RacwaFormLabel>
              </Grid>
              <Grid item xs={4} sx={styles.InputContainer} alignItems="center">
                <Box mr={1}>
                  <BasicTooltip id={tooltipId} data-testid={tooltipId} {...tooltipProps} />
                </Box>
                <NumericFormat
                  id={inputId}
                  value={value}
                  allowNegative={false}
                  thousandSeparator={(value as number) >= THOUSAND_SEPARATOR_THRESHOLD ? true : undefined}
                  decimalScale={0}
                  fixedDecimalScale={false}
                  prefix="$"
                  customInput={OutlinedInput}
                  onValueChange={(newValue) => {
                    setAgreedValue(newValue.floatValue);
                  }}
                  onBlur={() => {
                    gtm(fieldTouched(label));
                    setAgreedValueChanged();
                    onChange(agreedValue);
                    onBlur();
                  }}
                  onKeyDown={(e: any) => {
                    e.key === KEYBOARD_CODE_ENTER && e.preventDefault();
                  }}
                  onKeyPress={(e: any) => {
                    e.key === KEYBOARD_CODE_ENTER && e.preventDefault();
                  }}
                  onKeyUp={(e: any) => {
                    if (e.key === KEYBOARD_CODE_ENTER) {
                      e.preventDefault();
                      setAgreedValueChanged();
                      onChange(agreedValue);
                      onBlur();
                    }
                  }}
                  error={!!error}
                  inputRef={ref}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        )}
      />
      <Grid item xs={12} sx={{ ...styles.MessageContainer, ...(errors.agreedValue && styles.ErrorContent) }}>
        <Typography variant="body2">
          Based on current market value, we'll insure your car for {formattedMin} to {formattedMax}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AgreedValue;
