import { PaymentFrequency, useGetSessionState } from "raci-react-library";
import { useRecoilValue } from "recoil";
import { endorsementAtom, policyDetailsAtom, ratingResponseAtom } from "../../../../shared/atoms";
import { CONFIRMATION_SESSION_STATE_KEY } from "../../../../shared/constants";

import { EndorsePolicyResponse, PolicyDetailsResponse, RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { FormRoute } from "../../../../shared/routing/routes.config";
import { PaymentDetailsState } from "../../../Payment/types";
import { YourRenewalState } from "../../../YourRenewal/types";

export interface ConfirmationData {
  policyDetails: PolicyDetailsResponse;
  ratingResponse: RatingResponse;
  endorsementResponse: EndorsePolicyResponse;
  paymentFrequency: PaymentFrequency;
  paymentDetails?: PaymentDetailsState;
  premiumDifference?: number;
}

export enum RefundDestination {
  CreditCard = "Credit card",
  BankAccount = "Bank account",
}

export const useConfirmationData = () => {
  const policyDetails = useRecoilValue(policyDetailsAtom); // TODO - SPK-4687 - Use hook or atom or session state?
  const ratingResponse = useRecoilValue(ratingResponseAtom);
  const endorsementResponse = useRecoilValue(endorsementAtom);
  const yourRenewal = useGetSessionState<YourRenewalState>(FormRoute.YourRenewal);
  const paymentDetails = useGetSessionState<PaymentDetailsState>(FormRoute.Payment);

  const sessionData = sessionStorage.getItem(CONFIRMATION_SESSION_STATE_KEY);
  if (sessionData) {
    return JSON.parse(sessionData) as ConfirmationData;
  }

  const confirmationData: ConfirmationData = {
    policyDetails: policyDetails as PolicyDetailsResponse,
    ratingResponse: ratingResponse as RatingResponse,
    endorsementResponse: endorsementResponse as EndorsePolicyResponse,
    paymentFrequency: (yourRenewal.frequency as PaymentFrequency) ?? PaymentFrequency.Annual,
    paymentDetails,
    premiumDifference: yourRenewal.premiumDifference,
  };

  sessionStorage.setItem(CONFIRMATION_SESSION_STATE_KEY, JSON.stringify(confirmationData));

  return confirmationData;
};

export default useConfirmationData;
