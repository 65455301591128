import { PolicyRenewalCaravanPocApiException } from "raci-policy-renewal-caravan-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { policyDetailsAtom } from "../../../../shared/atoms";
import { POLICY_DETAILS_SESSION_STATE_KEY } from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { handleExceptionNavigation } from "../../../../shared/routing/routes.config";

const usePolicyDetails = (policyNumber?: string) => {
  const client = useBffApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  /**
   * TODO - Potentially remove setting isError and returning in response as an error would trigger navigation to error page
   */
  const [isError, setIsError] = useState(false);
  const [policyDetails, setPolicyDetails] = useRecoilState(policyDetailsAtom);

  useEffect(() => {
    let isCancelled = false;

    const getPolicyDetails = async (policyNumber: string) => {
      setIsError(false);
      setBackdrop(true);

      try {
        const response = (await client.getPolicyDetails(policyNumber)).result;

        // We support Renewal policies, and also Midterm policies if the toggle is enabled
        const isSupportedPolicy = response.isRenewal;

        if (!isSupportedPolicy) {
          throw new PolicyRenewalCaravanPocApiException(
            "Unsupported policy",
            HTTP_STATUS_CODE_BAD_REQUEST,
            "",
            {},
            undefined,
          );
        }

        setPolicyDetails(response);
        sessionStorage.setItem(POLICY_DETAILS_SESSION_STATE_KEY, JSON.stringify(response));
      } catch (ex) {
        setIsError(true);
        handleExceptionNavigation(navigate, "Policy Details", ex);
      } finally {
        setBackdrop(false);
      }
    };

    if (policyNumber && !isCancelled && !isError && !policyDetails) {
      getPolicyDetails(policyNumber);
    } else {
      const sessionPolicyDetails = sessionStorage.getItem(POLICY_DETAILS_SESSION_STATE_KEY);
      if (sessionPolicyDetails) {
        setPolicyDetails(JSON.parse(sessionPolicyDetails));
      }
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, policyNumber]);

  return { policyDetails, isError };
};

export default usePolicyDetails;
