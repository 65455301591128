import { event, gtm } from "@racwa/analytics";
import { addYears } from "date-fns";
import { PolicyDetailsResponse, RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { NotificationCard, dateFromDayMonthYearString } from "raci-react-library";
import { useEffect } from "react";

import { formatCurrency, formatDayOfMonth, toAustralianDateString } from "../../../../shared/utils";
import { getNextPaymentDate } from "./format";

export interface PaymentDayNotificationProps {
  policyDetails: PolicyDetailsResponse;
  ratingResponse: RatingResponse;
  preferredCollectionDay: number;
}

export const PaymentDayNotification: React.FC<PaymentDayNotificationProps> = ({
  policyDetails,
  ratingResponse,
  preferredCollectionDay,
}) => {
  const renewalDate = dateFromDayMonthYearString(policyDetails.startDate ?? new Date().toString());
  const nextCollectionDate = getNextPaymentDate(new Date(), renewalDate, preferredCollectionDay);
  const nextRenewalDate = toAustralianDateString(addYears(renewalDate, 1));
  const notificationTitle = `Your next payment will be ${formatCurrency(
    ratingResponse.monthlyPremium?.instalment!,
  )} on ${nextCollectionDate}`;
  const paragraphOne = `Your monthly payment date will be the ${formatDayOfMonth(
    preferredCollectionDay,
  )} of every month.`;
  const paragraphTwo = `Your policy will renew again on ${nextRenewalDate}. We'll send your renewal documents before this in case you'd like to make changes.`;
  const paragraphThree =
    "If the date you chose doesn't occur in a particular month, we'll deduct payment on the last day of that month.";

  useEffect(() => {
    gtm(event("Your next payment notification"));
  }, []);

  return (
    <NotificationCard
      severity="info"
      message={{
        title: notificationTitle,
        paragraphs: [paragraphOne, paragraphTwo, paragraphThree],
      }}
    />
  );
};
