import { PolicyRenewalCaravanPocApiException } from "raci-policy-renewal-caravan-clientproxy";
import { HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY, useLogger } from "raci-react-library";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { formCompletedAtom, policyPaymentStateAtom } from "../../../../shared/atoms";
import { FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { PaymentDetailsState } from "../../types";
import useCustomLogProperties from "../useCustomLogProperties";
import { useWestpacRetryDialog } from "../useWestpacDialog";

/** useSessionState<> result array to set the form state for different pages */
export interface SetFormState {
  /** useSessionState<PaymentDetailsState> result array to set the form state for Renewal PaymentDetails page */
  paymentDetailsState?: readonly [PaymentDetailsState, (values: PaymentDetailsState) => void];
}

export const useWestpacExceptionHandler = () => {
  const retryAfterHeaderKey = "retry-after";
  const realtimePaymentRequestName = "Realtime Payment";
  const cardRegistrationRequestName = "Card Registration";

  const navigate = useNavigateToRoute();
  const { logException } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const [, setIsWestpacRetryDialogOpen] = useWestpacRetryDialog();

  const setFormCompleted = useSetRecoilState(formCompletedAtom);
  const resetPolicyPaymentState = useResetRecoilState(policyPaymentStateAtom);
  const [, setPolicyPaymentState] = useRecoilState(policyPaymentStateAtom);

  /**
   * Handle all other exceptions and navigate to the System Unavailable page
   * @param requestName request name for logging purposes
   * @param exception optional exception for logging purposes
   */
  const handleWestpacExceptionNavigation = (requestName: string, exception?: any) => {
    setFormCompleted(true);
    resetPolicyPaymentState();
    //handleExceptionNavigation(navigate, requestName, exception);
  };

  /**
   * Handle Westpac Realtime Payment error and navigate to RealtimePaymentError Confirmation Page
   * @param setFormState useSessionState<> result array to set the form state for different pages
   */
  const handleWestpacPaymentErrorNavigation = (setFormState: SetFormState) => {
    if (setFormState.paymentDetailsState) {
      const setPaymentDetailsState = setFormState.paymentDetailsState[1];
      setPaymentDetailsState({ ...setFormState.paymentDetailsState[0], paymentError: true, isCompleted: true });
    }

    setFormCompleted(true);
    resetPolicyPaymentState();
    navigate(FormRoute.Confirmation);
  };

  /**
   * A 422 Unprocessable Entity response can be retried by the member resubmitting until they have exceeded their retry attempts,
   * after which we'll proceed as though to the:
   * - RealtimePaymentError confirmation page for Realtime (Immediate) Payments as the policy will be endorsed on the first payment attempt.
   * - SystemUnavailable error page for Credit Card Registrations as the policy will not be endorsed until the new card has been added (or a different payment method selected).
   *
   * Other exceptions should redirect to the System Unavailable page.
   * @param isRealtimePayment Is Westpac Realtime Payment or Westpac Card Registration
   * @param exception exception for logging purposes
   * @param SetFormState useSessionState result array to set the form state for different pages
   */
  const handleWestpacException = (isRealtimePayment: boolean, exception: any, setFormState: SetFormState) => {
    const requestName = isRealtimePayment ? realtimePaymentRequestName : cardRegistrationRequestName;
    const apiException = exception as PolicyRenewalCaravanPocApiException;

    if (apiException.status === HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY) {
      const hasRetryAttempt = !!Object.keys(apiException.headers).find(
        (key) => key.toLowerCase() === retryAfterHeaderKey,
      );

      if (!hasRetryAttempt) {
        if (isRealtimePayment) {
          handleWestpacPaymentErrorNavigation(setFormState);
        } else {
          handleWestpacExceptionNavigation(requestName);
        }
      } else {
        if (isRealtimePayment) {
          setPolicyPaymentState({ isCreditCardSelected: true, isPaymentMethodLocked: true, isRealtimePayment: true });
        }
        setIsWestpacRetryDialogOpen(true);
        return;
      }
    } else {
      logException({
        location: "handleWestpacException",
        message: exception,
        customProperties: customLogProperties,
      });
      handleWestpacExceptionNavigation(requestName, exception);
    }

    return;
  };

  return {
    handleWestpacExceptionNavigation,
    handleWestpacPaymentErrorNavigation,
    handleWestpacException,
  };
};

export default useWestpacExceptionHandler;
