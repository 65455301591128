import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { sessionIdAtom } from "../../atoms";

/**
 * Add custom properties like QuoteNumber/PolicyNumber/ClaimNumber/ReceiptNumber that will help triage issues.
 * See @microsoft/applicationinsights-common/types/Interfaces/IPartC
 * @returns dictionary containing additional custom properties to log ApplicationInsights as CustomDimensions
 */
const useCustomLogProperties = () => {
  const sessionId = useRecoilValue(sessionIdAtom);

  const customLogProperties: { [key: string]: string } = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    customLogProperties["Session ID"] = sessionId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return customLogProperties;
};

export default useCustomLogProperties;
