import { colors } from "@racwa/styles";

const styles = {
  ErrorContent: {
    color: colors.brandDangerNew,
  },
  GridContainer: {
    alignItems: "center",
    paddingTop: "24px",
  },
  InputContainer: {
    display: "flex",
  },
  MessageContainer: {
    display: "flex",
    paddingBottom: "24px",
  },
};

export default styles;
