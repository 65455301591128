import { RenewalRatingRequest } from "raci-policy-renewal-caravan-clientproxy";
import { AUD_CURRENCY, currencyFormatter, dateFromDayMonthYearString, EN_AU_LOCALE } from "raci-react-library";

export const createId = (prefix: string, suffix: string) => {
  return `${prefix}-${suffix}`;
};

export const formatCurrency = (value: number, shouldStripZeroCents: boolean | undefined = true) => {
  return currencyFormatter(value, {
    withDecimals: true,
    stripZeroCents: shouldStripZeroCents,
  });
};

export const formatCurrencyNoDecimals = (value: number) => {
  return Intl.NumberFormat(EN_AU_LOCALE, {
    style: "currency",
    currency: AUD_CURRENCY,
    minimumFractionDigits: 0,
  }).format(value);
};

export const formatDayOfMonth = (value: number) => {
  if ([11, 12, 13].includes(value)) {
    return value + "th";
  }

  switch (value % 10) {
    case 1:
      return value + "st";
    case 2:
      return value + "nd";
    case 3:
      return value + "rd";
  }

  return value + "th";
};

export const isDateBeforeToday = (date: string) => {
  const renewal = dateFromDayMonthYearString(date);
  renewal.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return renewal < today;
};

export const toAustralianDateString = (date: Date) => {
  return date.toLocaleDateString(EN_AU_LOCALE);
};

export const maskBankAccountNumber = (accountNumberSuffix: string): string => {
  return `*** ${accountNumberSuffix}`;
};

export const maskCreditCardNumber = (creditCardNumberSuffix: string): string => {
  return `**** **** **** ${creditCardNumberSuffix}`;
};

export const createArrayRange = (start: number, stop: number) => {
  return Array.from({ length: stop - start + 1 }, (_, index) => start + index);
};

export const isEmptyObject = (object: Object) => {
  return Object.keys(object).length === 0;
};

/**
 * Sanitise rating request by doing the following:
 * - Remove the flag for an existing address
 * @param request RatingRequest
 * @returns sanitised RatingRequest
 */
export const sanitiseRatingRequest = (request: RenewalRatingRequest): RenewalRatingRequest => {
  return {
    ...request,
  } as RenewalRatingRequest;
};
