import { RenewalEndorsementRequest } from "raci-policy-renewal-caravan-clientproxy";
import {
  PayLaterOption,
  PaymentFrequency,
  PaymentMethod,
  useGetSessionState,
  useLogger,
  useSessionState,
  useSetBackdrop,
  useWestpacScript,
} from "raci-react-library";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { endorsementAtom, formCompletedAtom, policyPaymentStateAtom } from "../../../../shared/atoms";
import { usePolicyNumber } from "../../../../shared/components/PolicyNumberProvider";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useCustomLogProperties from "../../../../shared/hooks/useCustomLogProperties";
import { FormRoute, formRoutesInfo, handleExceptionNavigation } from "../../../../shared/routing/routes.config";
import { YourRenewalState } from "../../../YourRenewal/types";
import { FORM_NAME_EMAIL } from "../../components/ConfirmEmail";
import {
  FORM_NAME_DIRECT_DEBIT_OPT_IN,
  FORM_NAME_PAYMENT_METHOD,
  FORM_NAME_PAY_LATER_OPTION,
  FORM_NAME_PREFERRED_COLLECTION_DAY,
  PaymentDetailsFormProps,
  PaymentDetailsState,
} from "../../types";
import useGetPaymentReferenceData from "../useGetPaymentReferenceData";
import { useWestpacRetryDialog } from "../useWestpacDialog";
import useWestpacExceptionHandler, { SetFormState } from "../useWestpacExceptionHandler";

export const usePaymentDetails = (): PaymentDetailsFormProps => {
  const apiClient = useBffApiClient();
  const navigate = useNavigate();
  const setBackdrop = useSetBackdrop();
  const { logException, logInfo } = useLogger();
  const customLogProperties = useCustomLogProperties();
  const { paymentReferenceData } = useGetPaymentReferenceData();
  const [isWestpacRetryDialogOpen, setIsWestpacRetryDialogOpen] = useWestpacRetryDialog();
  const { handleWestpacExceptionNavigation, handleWestpacPaymentErrorNavigation, handleWestpacException } =
    useWestpacExceptionHandler();
  const policyNumber = usePolicyNumber();
  const setFormCompleted = useSetRecoilState(formCompletedAtom);
  const setEndorsementAtom = useSetRecoilState(endorsementAtom);
  const resetPolicyPaymentState = useResetRecoilState(policyPaymentStateAtom);
  const policyPaymentState = useRecoilValue(policyPaymentStateAtom);
  const yourRenewalState = useGetSessionState<YourRenewalState>(FormRoute.YourRenewal);
  const [state, setState] = useSessionState<PaymentDetailsState>({
    specificKey: FormRoute.Payment,
  });

  const form = useForm<PaymentDetailsState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: state,
  });

  const paymentMethod = useWatch({ control: form.control, name: FORM_NAME_PAYMENT_METHOD });
  const payLaterOption = useWatch({ control: form.control, name: FORM_NAME_PAY_LATER_OPTION });
  const isDirectDebitOptedIn = useWatch({ control: form.control, name: FORM_NAME_DIRECT_DEBIT_OPT_IN });
  const email = useWatch({ control: form.control, name: FORM_NAME_EMAIL });
  const preferredCollectionDay = useWatch({ control: form.control, name: FORM_NAME_PREFERRED_COLLECTION_DAY });

  const agreedValue = yourRenewalState.agreedValue;
  const paymentFrequency = yourRenewalState.frequency as PaymentFrequency;
  const isCreditCardSelected = policyPaymentState.isPaymentMethodLocked
    ? policyPaymentState.isRealtimePayment
    : paymentMethod === PaymentMethod.Card || payLaterOption === PayLaterOption.Card;
  const isRealtimePayment = policyPaymentState.isPaymentMethodLocked
    ? policyPaymentState.isRealtimePayment
    : !isDirectDebitOptedIn && isCreditCardSelected && paymentFrequency === PaymentFrequency.Annual;

  // Keep our non-payment fields updated in case the user goes back and forth through the form.
  useEffect(
    () => {
      setState({ ...state, email, preferredCollectionDay });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, preferredCollectionDay],
  );

  const westpacScript = useWestpacScript(
    isCreditCardSelected,
    isRealtimePayment,
    paymentReferenceData?.westpacPublishableApiKey,
    customLogProperties,
  );

  const createRequest = (state: PaymentDetailsState, singleUseTokenId?: string): RenewalEndorsementRequest => {
    const isDeferringPayment =
      state.payLaterOption === PayLaterOption.PayLater || state.payLaterOption === PayLaterOption.BPAY;

    const existingAccountExternalNumber =
      !payLaterOption && state.isAddingAccount ? undefined : state.existingAccountExternalNumber;

    const newAccount =
      !isDeferringPayment && state.isAddingAccount && state.paymentMethod === PaymentMethod.BankAccount
        ? {
            accountName: state.accountName ?? "",
            accountNumber: state.accountNumber ?? "",
            bsb: state.bsb ?? "",
          }
        : undefined;

    const newCreditCardExternalNumber =
      !isDeferringPayment && state.isAddingAccount && state.paymentMethod === PaymentMethod.Card
        ? state.newCreditCardExternalNumber
        : undefined;

    return {
      frequency: paymentFrequency,
      email: state.email,
      isDeferringPayment,
      policyNumber: policyNumber,
      excess: yourRenewalState.excess ?? 50,
      agreedValue,
      annexeValue: yourRenewalState.annexeValue ?? 1000,
      contentsValue: yourRenewalState.contentsValue ?? 1000,
      existingAccountExternalNumber,
      newCreditCardExternalNumber,
      newAccount,
      preferredCollectionDay: state.preferredCollectionDay,
      token: singleUseTokenId,
    };
  };

  const onSubmit = async (values: PaymentDetailsState) => {
    if (isCreditCardSelected) {
      westpacScript.onSubmitWestpac(async (singleUseTokenId: string) => {
        try {
          setBackdrop(true);
          if (!isRealtimePayment) {
            const response = await apiClient.addCreditCard({
              policyNumber: policyNumber,
              frequency: paymentFrequency,
              token: singleUseTokenId,
            });

            values.newCreditCardExternalNumber = response.result.externalNumber;
            if (values.newCreditCardExternalNumber?.toString().trim() !== "") {
              logInfo({
                location: "usePaymentDetails.onSubmit",
                message: "Credit card registration completed successfully.",
                customProperties: customLogProperties,
              });
            }
            return performSubmit({ ...values, paymentError: false });
          }

          return performSubmit({ ...values, paymentError: false }, singleUseTokenId);
        } catch (ex) {
          if (!isRealtimePayment) {
            // Handle Credit Card Registration retries
            handleWestpacException(isRealtimePayment, ex, { paymentDetailsState: [values, setState] } as SetFormState);
          } else {
            logException({
              location: "usePaymentDetails.onSubmit",
              message: ex,
              customProperties: customLogProperties,
            });
            handleWestpacExceptionNavigation("Realtime Payment", ex);
          }
        } finally {
          setBackdrop(false);
        }
      });
    } else {
      return performSubmit(values);
    }
  };

  const performSubmit = async (values: PaymentDetailsState, token?: string) => {
    try {
      setBackdrop(true);
      const response = await apiClient.endorseRenewalPolicy(createRequest(values, token));
      setEndorsementAtom(response.result);
      setState({ ...values, isCompleted: true });
      setFormCompleted(true);
      resetPolicyPaymentState();
      navigate(formRoutesInfo[FormRoute.Confirmation].path);
    } catch (ex) {
      if (token && isRealtimePayment) {
        // Handle Realtime (Immediate) Credit Card Payment retries
        handleWestpacException(isRealtimePayment, ex, { paymentDetailsState: [values, setState] } as SetFormState);
      } else {
        logException({
          location: "usePaymentDetails.performSubmit",
          message: ex,
          customProperties: customLogProperties,
        });
        setFormCompleted(true);
        resetPolicyPaymentState();
        handleExceptionNavigation(navigate, "Renewal Endorsement", ex);
      }
    } finally {
      setBackdrop(false);
    }
  };

  return {
    onSubmit,
    form,
    isRealtimePayment,
    openWestpacRetryDialog: isWestpacRetryDialogOpen,
    onWestpacRetryTryAgainClick: () => setIsWestpacRetryDialogOpen(false),
    onWestpacRetryDoneClick: () =>
      handleWestpacPaymentErrorNavigation({ paymentDetailsState: [state, setState] } as SetFormState),
  };
};

export default usePaymentDetails;
