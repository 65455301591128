import { PayLaterOption } from "raci-react-library";
import { getPremiumAmount } from "../../../../shared/utils/getPremiumAmount";
import AnnualPolicyPaid from "../../components/AnnualPolicyPaid";
import BPAY from "../../components/BPAY";
import DirectDebitPolicyPaid from "../../components/DirectDebitPolicyPaid";
import PayLater from "../../components/PayLater";
import RealtimePaymentError from "../../components/RealtimePaymentError";
import { FormProps } from "../../types";
import useConfirmationData from "../useConfirmationData";

export const useConfirmation = (): FormProps => {
  const { policyDetails, ratingResponse, endorsementResponse, paymentDetails, paymentFrequency, premiumDifference } =
    useConfirmationData();

  const getConfirmationView = () => {
    if (!policyDetails) {
      // All screens require policy details
      return <></>;
    }

    const policyNumber = policyDetails.policySummary?.number;
    const memberFirstName = policyDetails.firstName;
    const receiptNumber = endorsementResponse?.receiptNumber ?? "";
    const directDebitDueDate = endorsementResponse?.collectionDate ?? "";
    const isPaymentError = paymentDetails?.paymentError === true;
    const renewalPayLaterOption = paymentDetails?.payLaterOption;
    const renewalStartDate = policyDetails.startDate;

    if (isPaymentError) {
      return (
        <RealtimePaymentError
          policyNumber={policyNumber!}
          isMidTerm={false}
          gracePeriodDueDate={""}
          showRoadside={false}
        />
      );
    }

    if (!ratingResponse || !endorsementResponse) {
      // All other screens require a rating or endorsement
      return <></>;
    }

    const renewalPremiumAmount = getPremiumAmount(paymentFrequency, ratingResponse);

    if (renewalPayLaterOption === PayLaterOption.PayLater) {
      return (
        <PayLater
          policyNumber={policyNumber!}
          renewalDate={(renewalStartDate ?? new Date()).toString()}
          gracePeriodDueDate={""}
          total={renewalPremiumAmount!}
          showRoadside={false}
        />
      );
    } else if (renewalPayLaterOption === PayLaterOption.BPAY) {
      return (
        <BPAY
          policyNumber={policyNumber!}
          renewalDate={(renewalStartDate ?? new Date()).toString()}
          gracePeriodDueDate={""}
          total={renewalPremiumAmount!}
          showRoadside={false}
        />
      );
    } else if (renewalPayLaterOption === PayLaterOption.Card) {
      return (
        <AnnualPolicyPaid
          policyNumber={policyNumber!}
          receiptNumber={receiptNumber}
          firstName={memberFirstName ?? ""}
          renewalDate={(renewalStartDate ?? new Date()).toString()}
          total={renewalPremiumAmount!}
          showRoadside={false}
        />
      );
    } else {
      return (
        <DirectDebitPolicyPaid
          frequency={paymentFrequency}
          policyNumber={policyNumber!}
          firstName={memberFirstName ?? ""}
          paymentMethod={
            paymentDetails?.isAddingAccount ? paymentDetails.paymentMethod : paymentDetails?.existingAccountType
          }
          renewalDate={(renewalStartDate ?? new Date()).toString()}
          dueDate={directDebitDueDate}
          total={renewalPremiumAmount!}
          showRoadside={false}
        />
      );
    }
  };

  return {
    confirmationView: getConfirmationView(),
  };
};

export default useConfirmation;
