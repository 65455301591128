import { Box, Grid } from "@mui/material";
import { PolicyDetailsResponse, RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { InsurancePhoneNumberLink, LargeLabel, PaymentFrequency } from "raci-react-library";

import PolicyDetailsCard from "../../../../shared/components/PolicyDetailsCard";
import { getFormattedPremiumAmount } from "../../../../shared/utils/getPremiumAmount";

export interface ViewPolicyDetailsProps {
  policyDetails?: PolicyDetailsResponse;
  ratingResponse?: RatingResponse;
  paymentFrequency: PaymentFrequency;
}

export const ViewPolicyDetails: React.FC<ViewPolicyDetailsProps> = ({
  policyDetails,
  ratingResponse,
  paymentFrequency,
}) => {
  const getFrequency = (paymentFrequency: PaymentFrequency) =>
    paymentFrequency === PaymentFrequency.Annual ? "Annually" : paymentFrequency;

  const getAmountSubtitle = (paymentFrequency: PaymentFrequency) =>
    paymentFrequency === PaymentFrequency.Monthly
      ? "Monthly amounts may vary slightly. Check the schedule in your policy documents for the exact amounts."
      : undefined;

  const getRenewsOnSubtitle = (policyDetails: PolicyDetailsResponse) => {
    return (
      <>
        Any changes you make will apply from {policyDetails.startDate}. If you want your changes to apply sooner, please
        call us on <InsurancePhoneNumberLink id={"apply-sooner-phone-number-link"} />.
      </>
    );
  };

  return (
    <>
      {policyDetails && ratingResponse && paymentFrequency && (
        <>
          <PolicyDetailsCard policy={policyDetails} />
          <Grid xs={12}>
            <Box mt={4}>
              <LargeLabel id="frequency" label="How often" value={getFrequency(paymentFrequency)} />
            </Box>

            <Box mt={4}>
              {ratingResponse && (
                <LargeLabel
                  id="amount"
                  label="Amount"
                  value={getFormattedPremiumAmount(paymentFrequency, ratingResponse)}
                  subtitle={getAmountSubtitle(paymentFrequency)}
                />
              )}
            </Box>

            <Box mt={4}>
              <LargeLabel
                id="start-date"
                label="Policy renews on"
                value={policyDetails.startDate ?? ""}
                subtitle={getRenewsOnSubtitle(policyDetails)}
              />
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default ViewPolicyDetails;
