import { addMonths } from "date-fns";
import getDaysInMonth from "date-fns/getDaysInMonth";
import { toAustralianDateString } from "../../../../shared/utils";

export const getNextPaymentDate = (now: Date, renewalDate: Date, preferredCollectionDay: number) => {
  const renewalDay = renewalDate.getDate();
  const renewalMonth = renewalDate.getMonth();
  const renewalYear = renewalDate.getFullYear();
  // Get today's date, ignoring time for comparison's sake
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  // The new collection date cannot be before the renewal date - move it forward a month in that case
  const collectionMonth = preferredCollectionDay < renewalDay ? renewalMonth + 1 : renewalMonth;
  const collectionMonthLength = getDaysInMonth(new Date(renewalYear, collectionMonth));

  const newCollectionDate = new Date(
    renewalYear,
    collectionMonth,
    // If collection day is greater than the month length, then use the last day of that month.
    preferredCollectionDay >= collectionMonthLength ? collectionMonthLength : preferredCollectionDay,
  );

  // Next collection date can't be in the past
  if (newCollectionDate < today) {
    return toAustralianDateString(addMonths(newCollectionDate, 1));
  }

  return toAustralianDateString(newCollectionDate);
};
