import { Excesses } from "raci-policy-renewal-caravan-clientproxy";
import { useRecoilState } from "recoil";
import { policyDetailsAtom } from "../../../../shared/atoms";

const useSetExcessOptions = () => {
  // TODO - SPK-4687 - Use policy hook or policy atom or state?
  const [policyDetails, setPolicyDetails] = useRecoilState(policyDetailsAtom);

  /**
   * Set the excess options on the Policy atom.
   * Excess options for the policy are currently only returned in Shield API Rating response, so both need to be updated.
   *
   * TODO - SPK-4687 - Atoms or Session State?
   * TODO - SPK-4687 - Look at storing the excess options on the form/page state as they are only used on the Premium page.
   * TODO - ISE-7585 - Ticket has been raised with Shield to add Excess options to Shield API GET Policy response:
   * - So all the relevant details required to display form are available from PolicyResponse
   * - Which will mean we won't have to maintain policy properties across PolicyResponse and RatingResponse
   * @param response RatingResponse
   */
  const setExcessOptionsOnPolicyAtom = (excesses: Excesses) => {
    if (policyDetails) {
      setPolicyDetails({
        ...policyDetails,
        excesses: {
          current: policyDetails.excesses?.current,
          options: excesses.options,
        },
      });
    }
  };

  return setExcessOptionsOnPolicyAtom;
};

export default useSetExcessOptions;
