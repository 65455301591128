import { InsertInvitation } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  InputAdornment,
  Typography,
} from "@mui/material";
import { fieldTouched, gtm } from "@racwa/analytics";
import { KEYBOARD_CODE_ENTER } from "raci-react-library";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { createArrayRange, formatDayOfMonth } from "../../../../shared/utils";
import { FORM_NAME_PREFERRED_COLLECTION_DAY, PaymentDetailsState } from "../../types";
import styles, { StyledBox, StyledTextField } from "./styles";

export interface PaymentDayPickerProps {
  defaultValue: number;
}

export const PaymentDayPicker: React.FC<PaymentDayPickerProps> = ({ defaultValue }) => {
  const { control } = useFormContext<PaymentDetailsState>();
  const [show, setShow] = useState<boolean>(false);
  const rows = [
    createArrayRange(1, 7),
    createArrayRange(8, 14),
    createArrayRange(15, 21),
    createArrayRange(22, 28),
    // Fill with null in order for the flex box to properly fill the grid.
    [...createArrayRange(29, 31), null, null, null, null],
  ];

  const label = "Preferred monthly payment date";
  const labelId = "payment-day-text-input";

  useEffect(() => {
    if (show) {
      gtm(fieldTouched(label));
    }
  }, [show]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={FORM_NAME_PREFERRED_COLLECTION_DAY}
      render={({ field: { onChange, value } }) => (
        <FormControl margin="none">
          <FormLabel htmlFor={labelId}>{label}</FormLabel>
          <Typography variant="body2">Choose your payment date for the next 12 months.</Typography>
          <Box
            onClick={() => {
              setShow(!show);
            }}
          >
            <StyledTextField
              id={labelId}
              test-id={labelId}
              fullWidth
              disabled
              tabIndex={0}
              value={`${formatDayOfMonth(value as number)} of every month`}
              onKeyDown={(e) => {
                if (e.key === KEYBOARD_CODE_ENTER) {
                  e.preventDefault();
                  setShow(!show);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InsertInvitation sx={styles.CalendarIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Dialog
            open={show}
            onClose={() => {
              setShow(false);
            }}
            maxWidth={"sm"}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <Typography id="payment-day-dialog-title" variant="h5" component="div" mt={1}>
                Preferred monthly payment date
              </Typography>
            </DialogTitle>
            <DialogContent style={{ padding: "0px 10px 10px 10px" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                {rows.map((items, index) => {
                  return (
                    <Box key={`row-${index}`} display="flex">
                      {items.map((val, subIndex) => {
                        return (
                          <StyledBox
                            id={val !== null ? `day-select-${val}` : undefined}
                            test-id={`day-select-${val}`}
                            key={`${subIndex}-value-${val}`}
                            role={val !== null ? "button" : undefined}
                            tabIndex={val !== null ? 0 : undefined}
                            sx={{ ...(val && styles.Item), ...(value === val && styles.Selected) }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                              if (val) {
                                setShow(false);
                                onChange(val);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === KEYBOARD_CODE_ENTER && val) {
                                e.preventDefault();
                                setShow(false);
                                onChange(val);
                              }
                            }}
                          >
                            <Typography variant="body1">{val}</Typography>
                          </StyledBox>
                        );
                      })}
                    </Box>
                  );
                })}
              </Box>
            </DialogContent>
          </Dialog>
        </FormControl>
      )}
    />
  );
};

export default PaymentDayPicker;
