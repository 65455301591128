import { PolicyRenewalCaravanPocApiException } from "raci-policy-renewal-caravan-clientproxy";
import { HTTP_STATUS_CODE_TIMEOUT, setIntervalGuarded } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorRoute } from "../../routing/routes.config";
import { useBffApiClient } from "../useApiClient";

export const EXPIRY_CHECK_INTERVAL = 30000;

export const useSessionExpiry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const client = useBffApiClient();
  const [expireInSeconds, setExpireInSeconds] = useState<number | null>(null);
  const [wait, setWait] = useState(EXPIRY_CHECK_INTERVAL);
  const [isError, setIsError] = useState(false);

  const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

  useEffect(() => {
    let isCancelled = false;

    const checkSessionExpiry = async () => {
      try {
        setIsError(false);
        const response = await client.getSessionExpiryInSeconds();
        if (!isCancelled) {
          const expiry = parseInt(response.result);
          setWait(getNextDelay(expiry));
          setExpireInSeconds(expiry);
        }
      } catch (ex) {
        const response = ex as PolicyRenewalCaravanPocApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT) {
          setWait(getNextDelay(-1));
          setExpireInSeconds(-1);
          navigate(ErrorRoute.SessionTimeout, {
            replace: true,
          });
        } else {
          setIsError(true);
        }
      }
    };

    const isSessionTimeoutPage = location.pathname.endsWith(HTTP_STATUS_CODE_TIMEOUT.toString());

    if (!isSessionTimeoutPage) {
      const handle = setIntervalGuarded(async () => await checkSessionExpiry(), wait);
      return () => {
        isCancelled = true;
        clearTimeout(handle);
      };
    }

    return () => {
      isCancelled = true;
    };
  }, [wait, client, navigate, location.pathname]);

  return {
    isError,
    expireInSeconds,
  };
};

export default useSessionExpiry;
