import { Box, Grid, Typography } from "@mui/material";
import { Card, FinePrint } from "@racwa/react-components";
import { RoadsideAssistanceCard } from "raci-react-library";
import { createId, formatCurrency } from "../../../../shared/utils";
import { styles } from "../../styles";
import BaseConfirmation from "../BaseConfirmation";

export interface AnnualPolicyPaidProps {
  firstName: string;
  policyNumber: string;
  receiptNumber: string;
  renewalDate: string;
  total: number;
  showRoadside: boolean;
}

export const AnnualPolicyPaid: React.FC<AnnualPolicyPaidProps> = ({
  firstName,
  policyNumber,
  receiptNumber,
  renewalDate,
  total,
  showRoadside,
}) => {
  const id = "annual-policy-paid";

  const getContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph")}>
              <b>You've renewed your policy and paid.</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card background="white" sx={styles.CardContainer}>
            <Grid container>
              <Grid item xs={8}>
                <Typography align="left" variant="h3" component="div" id={createId(id, "card-title")}>
                  Annual payment
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="h3" component="div" id={createId(id, "card-amount")}>
                  {formatCurrency(total)}
                </Typography>
              </Grid>
            </Grid>

            <Box mt={1}>
              <FinePrint align="left" id={createId(id, "payment-method")}>
                Payment: Credit card
              </FinePrint>
            </Box>

            <Box mt={1}>
              <FinePrint align="left" id={createId(id, "renews-on")}>{`Policy renews on: ${renewalDate}`}</FinePrint>
            </Box>

            {showRoadside && (
              <Box mt={3}>
                <RoadsideAssistanceCard />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <BaseConfirmation
      id={id}
      policyNumber={policyNumber}
      receiptNumber={receiptNumber}
      title={`You're all set, ${firstName}!`}
      content={getContent()}
    />
  );
};

export default AnnualPolicyPaid;
