import { Box, styled, TextField } from "@mui/material";
import { colors } from "@racwa/styles";

export const styles = {
  CalendarIcon: {
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.54)",
  },
  Item: {
    cursor: "pointer",
    border: "none",
    borderRadius: "100%",
    color: colors.dieselDeep,
    "&:hover": {
      backgroundColor: colors.racGrayLight,
    },
    "& p": {
      letterSpacing: "-1px",
    },
  },

  Selected: {
    backgroundColor: colors.racYellow,
    "& p": {
      fontWeight: "normal",
    },
  },
};

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    height: "45px",
    width: "45px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "40px",
    width: "40px",
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: theme.palette.common.white,
    color: "currentColor",
    cursor: "pointer",
  },
  "& input": {
    cursor: "pointer",
  },
}));

export default styles;
