import { useHasSession } from "raci-react-library";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { policyDetailsAtom } from "../../../../shared/atoms";
import { usePolicyNumber } from "../../../../shared/components/PolicyNumberProvider";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";

export const usePreForm = () => {
  const navigate = useNavigateToRoute();
  const apiClient = useBffApiClient();
  const hasSession = useHasSession();
  const policyNumber = usePolicyNumber();
  const [, setPolicyDetails] = useRecoilState(policyDetailsAtom);

  useEffect(() => {
    const getPolicyDetails = async () => {
      try {
        const policyDetails = (await apiClient.getPolicyDetails(policyNumber)).result;
        setPolicyDetails(policyDetails);
        navigate(FormRoute.YourRenewal);
      } catch (e) {
        navigate(ErrorRoute.SystemUnavailable);
      }
    };

    hasSession && getPolicyDetails();
  }, [apiClient, hasSession, navigate, policyNumber, setPolicyDetails]);
};

export default usePreForm;
