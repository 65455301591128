import { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { endorsementAtom, sessionIdAtom } from "../../../../shared/atoms";
import { usePolicyNumber } from "../../../../shared/components/PolicyNumberProvider";

/**
 * Add custom properties that will help triage issues.
 * See @microsoft/applicationinsights-common/types/Interfaces/IPartC
 * @returns dictionary containing additional custom properties to log ApplicationInsights as CustomDimensions
 */
const useCustomLogProperties = () => {
  const policyNumber = usePolicyNumber();
  const sessionId = useRecoilValue(sessionIdAtom);
  const receiptNumber = useRecoilValue(endorsementAtom)?.receiptNumber ?? "";

  const customLogProperties: { [key: string]: string } = useMemo(() => {
    return {};
  }, []);

  useEffect(() => {
    customLogProperties["Policy Number"] = policyNumber;
    customLogProperties["Session ID"] = sessionId;
    customLogProperties["Receipt Number"] = receiptNumber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyNumber, sessionId, receiptNumber]);

  return customLogProperties;
};

export default useCustomLogProperties;
