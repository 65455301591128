import { faExclamationTriangle, faInfoCircle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Card } from "@racwa/react-components";
import { BPAYCard, IconWithText } from "raci-react-library";
import { createId, formatCurrency, isDateBeforeToday } from "../../../../shared/utils";
import { styles } from "../../styles";
import BaseConfirmation from "../BaseConfirmation";

export interface BPAYProps {
  policyNumber: string;
  renewalDate: string;
  gracePeriodDueDate: string;
  total: number;
  showRoadside: boolean;
}

export const BPAY: React.FC<BPAYProps> = ({ policyNumber, renewalDate, gracePeriodDueDate, total, showRoadside }) => {
  const id = "bpay";

  const isOverdue = () => {
    return isDateBeforeToday(renewalDate);
  };

  const getIcon = () => {
    return isOverdue() ? faInfoCircle : faThumbsUp;
  };

  const getTitle = () => {
    return isOverdue() ? "Payment overdue" : "Please pay using BPAY";
  };

  const getParagraphOne = () => {
    return isOverdue()
      ? `But don't worry, we've extended your cover until ${gracePeriodDueDate}. Please pay to stay covered.`
      : `Please pay by ${renewalDate} to stay covered.`;
  };

  const getCardTitle = () => {
    return isOverdue() ? `${formatCurrency(total)} due now` : `${formatCurrency(total)} due by ${renewalDate}`;
  };

  const getContent = () => {
    return (
      <Grid container data-testid={createId(id, "confirmation")}>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph-one")}>
              <b>{getParagraphOne()}</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph-two")}>
              <b>You'll receive an email with your renewal documents shortly.</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card background="white" sx={styles.CardContainer}>
            <IconWithText
              icon={faExclamationTriangle}
              text={
                <Typography align="left" variant="h3" component="div" id={createId(id, "warning-title")}>
                  {getCardTitle()}
                </Typography>
              }
            />
            <Divider sx={{ my: 2 }} />
            <BPAYCard id={id} reference={policyNumber} showRoadside={showRoadside} />
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <BaseConfirmation id={id} icon={getIcon()} title={getTitle()} content={getContent()} policyNumber={policyNumber} />
  );
};

export default BPAY;
