import { useClearSession } from "raci-react-library";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { sessionIdAtom } from "../../shared/atoms";
import { ATOM_FORM_COMPLETED, ATOM_ROUTE_STATE, CONFIRMATION_SESSION_STATE_KEY } from "../../shared/constants";
import { StyledContainer } from "./styles";
import { FormProps } from "./types";

export const Form: React.FC<FormProps> = ({ confirmationView }) => {
  const clearSession = useClearSession();
  const setSessionId = useSetRecoilState(sessionIdAtom);

  useEffect(
    () => {
      // Clear all the session state except for that of the Confirmation page. We've completed
      // the flow so want to reset the session except for what we need to maintain the final page
      // (which is cleared on the previous screen).
      // - ATOM_FORM_COMPLETED so RouteGuard can force you back to Confirmation
      // - ATOM_ROUTE_STATE to maintain the routes and steps of a policy
      // - CONFIRMATION_SESSION_STATE_KEY the required confirmation data
      // - HAS_TIMED_OUT_STATE_KEY remember if the member has been timed out
      clearSession(ATOM_FORM_COMPLETED, ATOM_ROUTE_STATE, CONFIRMATION_SESSION_STATE_KEY);
      setSessionId("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <StyledContainer>{confirmationView}</StyledContainer>;
};

export default Form;
