import { createContext, ReactNode, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { STORAGE_KEY_POLICY_NUMBER } from "../../constants";

export const PolicyNumberContext = createContext<string | null | undefined>(undefined);

export const PolicyNumberProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams();
  const queryParamPolicyNumber = searchParams.get("policyNumber");

  const sessionStoragePolicyNumber = sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);

  useEffect(() => {
    queryParamPolicyNumber && sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, queryParamPolicyNumber ?? "");
  }, [queryParamPolicyNumber]);

  return (
    <PolicyNumberContext.Provider value={queryParamPolicyNumber ?? sessionStoragePolicyNumber}>
      {children}
    </PolicyNumberContext.Provider>
  );
};

export const usePolicyNumber = () => {
  const policyNumber = useContext(PolicyNumberContext);

  if (policyNumber === undefined) {
    throw new Error("usePolicyNumber must be used within a PolicyNumberProvider");
  }

  return policyNumber ?? "";
};

export default PolicyNumberProvider;
