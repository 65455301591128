import { event, gtm } from "@racwa/analytics";
import { PolicyDetailsCard as PolicyDetailsCardDs, PolicyProperty, getProductTypeIcon } from "@racwa/react-components";
import { PolicyDetailsResponse } from "raci-policy-renewal-caravan-clientproxy";

export interface PolicyDetailsProps {
  policy: PolicyDetailsResponse;
}

export const PolicyDetailsCard: React.FC<PolicyDetailsProps> = ({ policy }) => {
  const pdsLinkText = "Product Disclosure Statement";

  const getPolicyTitle = () => policy.policySummary?.title;
  const getPolicySubtitle = () => policy.policySummary?.subtitle ?? "";
  const getIcon = () => getProductTypeIcon(policy?.policySummary?.type ?? "");

  // Override existing policy properties with those set during the flow
  const getPolicyProperties = (policyDetails: PolicyDetailsResponse) => {
    const properties = policyDetails?.policySummary?.properties?.map((x) => {
      const property: PolicyProperty = { key: x.key, value: x.value };

      return property;
    });

    return properties;
  };

  return (
    <PolicyDetailsCardDs
      id="policy-details"
      policyTitle={getPolicyTitle()}
      policySubtitle={getPolicySubtitle()}
      properties={getPolicyProperties(policy)}
      cardActionTitle={pdsLinkText}
      cardActionLink={process.env.REACT_APP_PDS_PED_URL}
      cardActionOnClick={() => gtm(event(pdsLinkText))}
      minWidth="296px"
      maxWidth="696px"
    >
      {getIcon()}
    </PolicyDetailsCardDs>
  );
};

export default PolicyDetailsCard;
