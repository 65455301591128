import { useSessionState } from "raci-react-library";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { previousRatingRequestAtom, ratingResponseAtom } from "../../../../shared/atoms";

import { RenewalEndorsementRequest, RenewalRatingRequest } from "raci-policy-renewal-caravan-clientproxy";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { FormRoute, handleExceptionNavigation } from "../../../../shared/routing/routes.config";
import { sanitiseRatingRequest } from "../../../../shared/utils";
import { YourRenewalState } from "../../types";
import useRatingBackdrop from "../useRatingBackdrop";
import useSetExcessOptions from "../useSetExcessOptions";
import useValidateRatingRequest from "../useValidateRatingRequest";

export const useRating = (ratingRequest: RenewalEndorsementRequest | undefined) => {
  const client = useBffApiClient();
  const navigate = useNavigate();
  const setRatingBackdrop = useRatingBackdrop();
  const isRatingRequestValid = useValidateRatingRequest();
  const setExcessOptionsOnPolicyAtom = useSetExcessOptions();

  const setPreviousRatingRequest = useSetRecoilState(previousRatingRequestAtom);
  const [renewalRatingResponse, setRenewalRatingResponse] = useRecoilState(ratingResponseAtom);

  const [yourRenewalState, setYourRenewalState] = useSessionState<YourRenewalState>({
    specificKey: FormRoute.YourRenewal,
    skipPageTrackingRecalculation: true,
  });

  useEffect(() => {
    let isCancelled = false;

    const getRating = async (request: RenewalRatingRequest) => {
      setRatingBackdrop(true, renewalRatingResponse);

      try {
        setPreviousRatingRequest(request);

        const ratingResponse = (await client.rateRenewal(sanitiseRatingRequest(request))).result;

        const excesses = ratingResponse.excesses;
        if (excesses) {
          setExcessOptionsOnPolicyAtom(excesses);
        }

        setRenewalRatingResponse(ratingResponse);
        setYourRenewalState({
          ...yourRenewalState,
          excess: request.excess ?? 0,
          agreedValue: request.agreedValue,
          contentsValue: request.contentsValue,
          annexeValue: request.annexeValue,
          ratingResponse: ratingResponse,
        });
      } catch (exception) {
        handleExceptionNavigation(navigate, "RenewalRating", exception);
      } finally {
        setRatingBackdrop(false, renewalRatingResponse);
      }
    };

    if (!isCancelled && ratingRequest && isRatingRequestValid(ratingRequest)) {
      getRating(ratingRequest);
    }

    return () => {
      isCancelled = true;
    };
  }, [
    client,
    isRatingRequestValid,
    navigate,
    ratingRequest,
    renewalRatingResponse,
    setExcessOptionsOnPolicyAtom,
    setPreviousRatingRequest,
    setRatingBackdrop,
    setRenewalRatingResponse,
    setYourRenewalState,
    yourRenewalState,
  ]);

  return { ratingResponse: renewalRatingResponse, yourRenewalState, setYourRenewalState };
};

export default useRating;
