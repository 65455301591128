import { AccountSelectionFormState, PayLaterOptionState, SessionState } from "raci-react-library";
import { UseFormReturn } from "react-hook-form";

export const FORM_NAME_PAYMENT_METHOD = "paymentMethod";
export const FORM_NAME_PAY_LATER_OPTION = "payLaterOption";
export const FORM_NAME_PREFERRED_COLLECTION_DAY = "preferredCollectionDay";
export const FORM_NAME_DIRECT_DEBIT_OPT_IN = "isOptedInToDirectDebit";
export const FORM_ACCEPTED_TERMS_REALTIME = "hasAcceptedTermsRealtime";
export const FORM_ACCEPTED_TERMS_DIRECT_DEBIT = "hasAcceptedTermsDirectDebit";

export interface PaymentDetailsState extends SessionState, AccountSelectionFormState, PayLaterOptionState {
  newCreditCardExternalNumber?: string;
  isOptedInToDirectDebit?: boolean;
  email: string;
  preferredCollectionDay?: number;
  paymentError?: boolean;
  hasAcceptedTermsRealtime?: boolean;
  hasAcceptedTermsDirectDebit?: boolean;
}

export interface PaymentDetailsFormProps {
  form: UseFormReturn<PaymentDetailsState>;
  onSubmit: (answers: PaymentDetailsState) => Promise<void>;
  isRealtimePayment: boolean;
  openWestpacRetryDialog: boolean;
  onWestpacRetryTryAgainClick: (open: boolean) => void;
  onWestpacRetryDoneClick: () => void;
}
