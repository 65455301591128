import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { RacwaFormLabel, RacwaSelect } from "@racwa/react-components";
import { BasicTooltip, currencyFormatter, formatIdString } from "raci-react-library";
import { Controller, useFormContext } from "react-hook-form";
import { YourRenewalState } from "../../types";
import styles from "../AgreedValue/styles";

export interface ContentsCoverProps {
  coverOptions: number[];
  current: number;
  onChangeValues: (value: YourRenewalState) => void;
}

const label = "Contents cover";
const id = "contents-cover";
const inputId = formatIdString(id + "input");
const tooltipId = formatIdString(id + "tooltip");

const tooltipProps = {
  showDialog: false,
  title: "Contents cover",
  message: (
    <Typography variant="body2" sx={{ fontWeight: 400 }}>
      We provide $1000 of complimentary contents cover against loss or damage caused by fire, storm, collision, theft or
      malicious damage.
      <p>
        Contents includes clothing, personal belongings, furniture, sports equipment and electrical devices when inside
        or attached to your caravan or annexe.
      </p>
      For an additional premium, you can increase your cover up to $15,000.
    </Typography>
  ),
};

export const ContentsCover: React.FC<ContentsCoverProps> = ({ coverOptions, current, onChangeValues }) => {
  const { control } = useFormContext<YourRenewalState>();

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name={"contentsValue"}
        defaultValue={current}
        aria-label={id}
        rules={{
          required: { value: true, message: "Please select one" },
        }}
        render={({ field: { value, ref, onChange: onComponentChange, onBlur, ...props }, fieldState: { error } }) => (
          <Box mt={0} mb={1}>
            <Grid container sx={{ alignItems: "center", paddingTop: "12px" }}>
              <Grid item xs={8}>
                <RacwaFormLabel id={formatIdString(id + "label")} htmlFor={inputId} error={!!error}>
                  {label}
                </RacwaFormLabel>
              </Grid>
              <Grid item xs={4} sx={styles.InputContainer} alignItems="center">
                <Box mr={1}>
                  <BasicTooltip id={tooltipId} data-testid={tooltipId} {...tooltipProps} />
                </Box>
                <RacwaSelect
                  id={id}
                  defaultValue={current}
                  onChange={async (e) => {
                    const value = e.target.value as string;
                    onComponentChange && onComponentChange(value);
                    // onChangeValues({ contentsValue: parseInt(value) });
                  }}
                  error={!!error}
                  helperText={error?.message || ""}
                  inputRef={ref}
                >
                  {coverOptions?.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {currencyFormatter(value, { stripZeroCents: true, withDecimals: false })}
                      </MenuItem>
                    );
                  })}
                </RacwaSelect>
              </Grid>
            </Grid>
          </Box>
        )}
      />
      <Grid item xs={12} sx={{ ...styles.MessageContainer }}>
        <Typography variant="body2">$1000 of contents cover is included for free</Typography>
      </Grid>
    </Grid>
  );
};
