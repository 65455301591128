import { PolicyRenewalCaravanPocApiClient } from "raci-policy-renewal-caravan-clientproxy";
import { useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";
import { ADB2C_CLIENT_ID, BFF_API_BASE_URL } from "../../constants";

const useRealBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();

  return useSsoApiClient({
    Client: PolicyRenewalCaravanPocApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
  });
};

export let useBffApiClient = useRealBffApiClient;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  // DEV/SIT require Racwa-Internal-Authorization header when accessed outside internal network
  const { BypassService } = require("raci-react-library-npe");
  const { useNpeBffApiClient } = require("../../../__NPE__/useNpeApiClient");
  useBffApiClient = useNpeBffApiClient;

  if (process.env.REACT_APP_ENVIRONMENT === "UAT" && !BypassService.isUsingTestLaunchPage()) {
    useBffApiClient = useRealBffApiClient;
  }
}
