import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { westpacRetryDialogAtom } from "../../../../shared/atoms";

export const useWestpacRetryDialog = () => {
  const [isWestpacRetryDialogOpen, setIsWestpacRetryDialogOpen] = useRecoilState(westpacRetryDialogAtom);

  const setIsWestpacRetryDialogOpenWrapper = useCallback(
    (isOpen: boolean) => setIsWestpacRetryDialogOpen(isOpen),
    [setIsWestpacRetryDialogOpen],
  );

  return [isWestpacRetryDialogOpen, setIsWestpacRetryDialogOpenWrapper] as const;
};
