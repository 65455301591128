import { EmailInput } from "raci-react-library";

export const FORM_NAME_EMAIL = "email";

export interface ConfirmEmailProps {
  defaultEmail: string;
  disabled?: boolean;
  /** The name of the input which should align with the Form Name constant. */
  name?: string;
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ defaultEmail, disabled, name }) => {
  const emailSublabel = "We'll update your contact details (not your login email) if you provide a new email.";

  return (
    <EmailInput
      name={name ?? FORM_NAME_EMAIL}
      label="Confirm your email address"
      sublabel={emailSublabel}
      defaultValue={defaultEmail}
      disabled={disabled}
      formControlMargin="none"
    />
  );
};

export default ConfirmEmail;
