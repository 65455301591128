import { HTTP_STATUS_CODE_OK, LoadingStatus, useSessionIdStorageKey } from "raci-react-library";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { sessionIdAtom } from "../../atoms";
import { useBffApiClient } from "../useApiClient";

export const useCreateSession = () => {
  const client = useBffApiClient();
  const sessionIdStorageKey = useSessionIdStorageKey();
  const setSessionId = useSetRecoilState(sessionIdAtom);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);
      const hasSessionId = sessionStorage.getItem(sessionIdStorageKey);
      try {
        if (!isCancelled && !hasSessionId) {
          const response = await client.createSession();
          sessionStorage.setItem(sessionIdStorageKey, response.result);
          setSessionId(response.result);
          if (response.status !== HTTP_STATUS_CODE_OK) {
            setIsError(true);
          }
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, sessionIdStorageKey]);

  return isLoading ? LoadingStatus.Loading : isError ? LoadingStatus.Error : LoadingStatus.Successful;
};

export default useCreateSession;
