import { RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { PaymentFrequency, SessionState } from "raci-react-library";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

export const FORM_NAME_SELECTED_FREQUENCY = "frequency";
export const FORM_NAME_SELECTED_EXCESS = "excess";
export const FORM_NAME_SELECTED_AGREED_VALUE = "agreedValue";
export const FORM_NAME_SELECTED_AGREED_VALUE_CHANGED = "agreedValueChanged";
export const FORM_NAME_SELECTED_CONTENTS_COVER = "contentsValue";
export const FORM_NAME_SELECTED_ANNEXE_COVER = "annexeValue";
export const FORM_NAME_REFUND_DETAILS = "refundDetails";
export const FORM_NAME_PREMIUM_CHANGE_STATE = "premiumChangeState";
export const FORM_NAME_PREMIUM_DIFFERENCE = "premiumDifference";

export interface YourRenewalFormProps {
  form: UseFormReturn<YourRenewalState>;
  policyNumber: string;
  onSubmit: SubmitHandler<YourRenewalState>;
  onChangeValues: (answers: YourRenewalState) => void;
}

export interface YourRenewalState extends SessionState {
  frequency?: PaymentFrequency;
  excess?: number;
  agreedValue?: number | undefined;
  agreedValueChanged: boolean;
  annexeValue?: number;
  contentsValue?: number;
  premiumDifference?: number;
  isAgreedValueValid: boolean | undefined;
  ratingResponse: RatingResponse;
}
