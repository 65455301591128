import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Box, Grid, Typography } from "@mui/material";
import { RoadsideAssistanceCard } from "raci-react-library";
import { createId } from "../../../../shared/utils";
import BaseConfirmation from "../BaseConfirmation";

export interface RealtimePaymentErrorProps {
  policyNumber: string;
  isMidTerm: boolean;
  /** Date that payment is due by. Renewal and MidTerm have different number of grace period days. */
  gracePeriodDueDate: string;
  showRoadside: boolean;
}

/**
 * Confirmation page to display when the payment method is Annual Cash and realtime (immediate) payment failed on final retry attempt.
 * Policy endorsement happens before payment which is why a confirmation page is shown rather than an error page.
 */
export const RealtimePaymentError: React.FC<RealtimePaymentErrorProps> = ({
  policyNumber,
  isMidTerm,
  gracePeriodDueDate,
  showRoadside,
}) => {
  const id = "realtime-payment-error";

  const getContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph-one")}>
              <b>We couldn't process your payment.</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph-two")}>
              <b>
                {isMidTerm
                  ? "But we updated your policy and emailed your policy documents."
                  : "But we renewed your policy and emailed your renewal documents."}
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography id={createId(id, "paragraph-three")} sx={{ whiteSpace: "pre-wrap" }}>
              <b>
                Your payment is due {gracePeriodDueDate}.
                {isMidTerm ? "" : "\nIf you forget to pay, your policy will be cancelled."}
              </b>
            </Typography>
          </Box>
        </Grid>

        {showRoadside && (
          <Grid item xs={12}>
            <Box mb={2}>
              <RoadsideAssistanceCard />
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BaseConfirmation
      id={id}
      title="Please call us to pay"
      icon={faPhone}
      content={getContent()}
      policyNumber={policyNumber}
      showPhoneButton={true}
    />
  );
};

export default RealtimePaymentError;
