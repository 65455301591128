import { RenewalRatingRequest } from "raci-policy-renewal-caravan-clientproxy";
import { useGetSessionState } from "raci-react-library";
import { useRecoilValue } from "recoil";
import { previousRatingRequestAtom } from "../../../../shared/atoms";
import { FormRoute } from "../../../../shared/routing/routes.config";
import { YourRenewalState } from "../../types";

const useValidateRatingRequest = () => {
  const previousRatingRequest = useRecoilValue(previousRatingRequestAtom);
  const yourRenewalState = useGetSessionState<YourRenewalState>(FormRoute.YourRenewal);

  /**
   * Determine if a rating request is valid before calling Shield.
   * @param request RenewalRatingRequest
   * @returns boolean value indicating if rating request is valid
   */
  const isRatingRequestValid = (request: RenewalRatingRequest) => {
    const isAgreedValueValid =
      yourRenewalState.isAgreedValueValid === true &&
      ((request.agreedValue === undefined && !yourRenewalState.agreedValueChanged) ||
        (request.agreedValue !== undefined && yourRenewalState.agreedValueChanged));
    const isValid =
      // PreviousRatingRequest will be undefined when first rating request occurs
      (previousRatingRequest === undefined ||
        // Only trigger subsequent rating requests if current request does not match previous request
        JSON.stringify(previousRatingRequest) !== JSON.stringify(request)) &&
      isAgreedValueValid &&
      request.excess !== undefined &&
      request.excess > -1;

    return isValid;
  };

  return isRatingRequestValid;
};

export default useValidateRatingRequest;
