import { faPhone, faThumbsUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Typography } from "@mui/material";
import { gtm, phoneCall } from "@racwa/analytics";
import { FadeWiggleIcon, FinePrint } from "@racwa/react-components";
import { InsurancePhoneNumber } from "raci-react-library";
import { createId } from "../../../../shared/utils";
import { getB2CPCMUrl } from "../../../../shared/utils/getB2CPCMUrl";
import { iconStyles, styles } from "../../styles";

export interface BaseConfirmationProps {
  id: string;
  title: string;
  policyNumber: string;
  receiptNumber?: string;
  content: JSX.Element;
  icon?: IconDefinition;
  showPhoneButton?: boolean;
}

export const BaseConfirmation: React.FC<BaseConfirmationProps> = ({
  id,
  title,
  policyNumber,
  receiptNumber,
  content,
  icon = faThumbsUp,
  showPhoneButton = false,
}) => {
  const phoneButton = (
    <Box my={3}>
      <Button
        id={createId(id, "phone-button")}
        startIcon={<FontAwesomeIcon icon={faPhone} />}
        color="primary"
        href={InsurancePhoneNumber.href}
        onClick={() => gtm(phoneCall(InsurancePhoneNumber.displayWithoutSpaces))}
        fullWidth
        role="button"
      >
        {InsurancePhoneNumber.display}
      </Button>
    </Box>
  );

  return (
    <Grid container sx={styles.Container}>
      <Grid item xs={12}>
        <Box mb={3}>
          <FadeWiggleIcon icon={icon} style={iconStyles} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={3}>
          <Typography variant="h2" id={createId(id, "title")}>
            {title}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {content}
      </Grid>

      <Grid item xs={12}>
        <Box mt={3}>
          <FinePrint id={createId(id, "policy-number")}>Policy number: {policyNumber}</FinePrint>
        </Box>
      </Grid>

      {receiptNumber && (
        <>
          <Grid item xs={12}>
            <Box mt={1}>
              <FinePrint id={createId(id, "receipt-number")}>Receipt number: {receiptNumber}</FinePrint>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={1}>
              <FinePrint id={createId(id, "receipt-footer")}>
                Please save a copy of this screen for your records.
              </FinePrint>
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Box my={3}>
          {showPhoneButton && phoneButton}
          <Button
            id={createId(id, "pcm-button")}
            color={showPhoneButton ? "secondary" : "primary"}
            href={getB2CPCMUrl(policyNumber)}
            target="_blank"
            rel="noopener"
            fullWidth
          >
            Back to my policies
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default BaseConfirmation;
