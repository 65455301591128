import { PolicyDetailsResponse, RatingResponse } from "raci-policy-renewal-caravan-clientproxy";
import { PayLaterOption, PaymentFrequency, PaymentMethod, toAustralianDateString } from "raci-react-library";
import {
  AgreedValue,
  EndorsePolicyResponse,
  Excesses,
  PolicyPaymentState,
  RenewalRatingRequest,
} from "../../shared/atoms/types";
import { PaymentDetailsState } from "../../views/Payment/types";
import { YourRenewalState } from "../../views/YourRenewal/types";

export const createDate = (daysAdded: number = 0): Date => {
  const date = new Date();
  date.setDate(date.getDate() + daysAdded);
  return date;
};

export const createDateString = (daysAdded: number = 0): string => toAustralianDateString(createDate(daysAdded));

/**
 * Policy Response returns an empty array for options.
 * Options are only returned in Rating Response.
 */
export const getMockExcesses = (withOptions: boolean = true): Excesses => {
  return {
    current: 100,
    options: withOptions ? [0, 100, 200] : [],
  };
};

export const getAgreedValue = (): AgreedValue => {
  return {
    min: 1400,
    max: 2600,
    current: 2000,
  };
};

export const getMockDefaultRatingRequest = (policyResponse?: PolicyDetailsResponse): RenewalRatingRequest => {
  policyResponse = policyResponse ?? getMockPolicyResponse();
  return {
    policyNumber: policyResponse?.policySummary?.number,
    newVehicleId: undefined,
    hasModifications: true,
    financierExternalNumber: undefined,
    riskAddress: "",
    excess: policyResponse?.excesses?.current,
    agreedValue: undefined, // Set to undefined to indicate no AgreedValue change
    hasHireCar: policyResponse?.hasHireCar,
    hasNcbProtection: policyResponse?.hasNcbProtection,
  } as RenewalRatingRequest;
};

export const getMockRatingResponse = (): RatingResponse => {
  return {
    annualPremium: {
      basic: 10,
      governmentCharges: 20,
      gst: 30,
      total: 40,
    },
    monthlyPremium: {
      basic: 50,
      governmentCharges: 60,
      gst: 70,
      total: 80,
      instalment: 90,
    },
    excesses: getMockExcesses(),
  };
};

export const getMockUpdatedRatingResponse = (): RatingResponse => {
  return {
    annualPremium: {
      basic: 19,
      governmentCharges: 29,
      gst: 39,
      total: 49,
    },
    monthlyPremium: {
      basic: 59,
      governmentCharges: 69,
      gst: 79,
      total: 89,
      instalment: 99,
    },
    excesses: getMockExcesses(),
  };
};

export const getMockPolicyPaymentState = (
  isPaymentMethodLocked: boolean = false,
  isRealtimePayment: boolean = false,
  isCreditCardSelected: boolean = false,
): PolicyPaymentState => {
  return {
    isPaymentMethodLocked,
    isRealtimePayment,
    isCreditCardSelected,
  };
};

export const getMockLockedPolicyPaymentState = () => getMockPolicyPaymentState(true, true, true);

export const getMockEndorsementResponse = (): EndorsePolicyResponse => {
  return {
    receiptNumber: "123-456",
    collectionDate: createDateString(28),
  };
};

export const getMockPolicyResponse = (
  isMonthly: boolean = false,
  isMidterm: boolean = false,
  hasNcbProtection?: boolean | undefined,
  hasHireCar?: boolean | undefined,
  defaultRiskAddress?: string | undefined,
): PolicyDetailsResponse => {
  return {
    isRenewal: !isMidterm,
    isMidterm: isMidterm,
    frequency: isMonthly ? PaymentFrequency.Monthly : PaymentFrequency.Annual,
    firstName: "Uno",
    email: "uno@rac.com.au",
    hasRoadside: true,
    coverType: "MFCO",
    startDate: toAustralianDateString(new Date()),
    dueDate: toAustralianDateString(new Date()),
    maxMidTermEndorsementStartDate: isMidterm ? toAustralianDateString(createDate(14)) : undefined,
    preferredCollectionDay: 7,
    excesses: getMockExcesses(false),
    agreedValue: getAgreedValue(),
    isPaidInFull: true,
    hasHireCar: hasHireCar,
    hasNcbProtection: hasNcbProtection,
    hasModifications: true,
    paymentMethod: "Bank account",
    financierExternalNumber: "FIN777",
    vehicleRegistration: "RAC 123",
    riskAddress: defaultRiskAddress,
    policySummary: {
      number: "MGV335539829",
      type: "MGV",
      title: "Caravan & trailer insurance",
      properties: [
        { key: "Model", value: "Jayco Swan" },
        { key: "Registration number", value: "1ASD789" },
        { key: "Policy number", value: "MGV335539829" },
      ],
    },
    vehicle: {
      number: "",
      type: "MGP",
      title: "2023 Jayco Swan",
      subtitle: "Caravan",
      properties: [{ key: "Registration", value: "1ASD789" }],
    },
  };
};

export const getMockYourRenewalState = (
  isMidTerm: boolean,
  isCompleted: boolean = true,
  frequency?: PaymentFrequency,
  hasHireCar?: boolean,
  hasNcbProtection?: boolean,
  ratingResponse?: RatingResponse,
): YourRenewalState => {
  return {
    frequency: (frequency ?? getMockPolicyResponse().frequency) as PaymentFrequency,
    excess: getMockExcesses().current,
    agreedValue: undefined,
    ratingResponse: ratingResponse ?? getMockRatingResponse(),
    isCompleted: isCompleted,
    agreedValueChanged: false,
    isAgreedValueValid: true,
  };
};

export const getMockPaymentDetails = (
  isCompleted: boolean = true,
  isAddingAccount: boolean = false,
  payLaterOption?: PayLaterOption | undefined,
  paymentMethod?: PaymentMethod | undefined,
  existingAccountType?: PaymentMethod | undefined,
  existingAccountExternalNumber?: string,
  email?: string,
): PaymentDetailsState => {
  return {
    isCompleted,
    isAddingAccount,
    payLaterOption,
    paymentMethod,
    existingAccountType,
    existingAccountExternalNumber: existingAccountExternalNumber ?? "",
    email: email ?? "",
  };
};
