import { useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { usePolicyNumber } from "../../../../shared/components/PolicyNumberProvider";
import { FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { YourRenewalFormProps, YourRenewalState } from "../../types";

export const useYourRenewal = (): YourRenewalFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const policyNumber = usePolicyNumber();
  const [sessionState, setSessionState] = useSessionState<YourRenewalState>();

  const form = useForm<YourRenewalState>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: sessionState,
  });

  const onChangeValues = (value: YourRenewalState) => {
    setSessionState({ ...sessionState, ...value, isCompleted: false });
    setBackdrop(true);
    // TODO: Actually recalculate the premium difference
    setTimeout(() => {
      setBackdrop(false);
    }, 1500);
  };

  const onSubmit: YourRenewalFormProps["onSubmit"] = async (newValues) => {
    setSessionState({ ...newValues, isCompleted: true });
    setBackdrop(true);
    setTimeout(() => {
      navigate(FormRoute.Payment);
      setBackdrop(false);
    }, 1500);
  };

  return {
    form,
    policyNumber,
    onSubmit,
    onChangeValues,
  };
};

export default useYourRenewal;
