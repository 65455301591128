import { Box, Button, Divider, Grid } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import {
  AnnualPaymentOptionsSelection,
  FORM_NAME_EXISTING_PAYMENT_ACCOUNT,
  FORM_NAME_EXISTING_PAYMENT_ACCOUNT_TYPE,
  FORM_NAME_IS_ADDING_ACCOUNT,
  PaymentFrequency,
  dateFromDayMonthYearString,
  getRenewalEndorsementRetryDialog,
  isEmptyObject,
  useGetSessionState,
} from "raci-react-library";
import { useEffect } from "react";
import { FieldErrors, FormProvider, useWatch } from "react-hook-form";
import { useRecoilValue } from "recoil";

import ConfirmEmail from "./components/ConfirmEmail";
import PaymentSelection from "./components/PaymentSelection";

import { RacwaStepperPageTitle } from "@racwa/react-components";
import { getMockRatingResponse } from "../../__NPE__/testData";
import { policyPaymentStateAtom, ratingResponseAtom } from "../../shared/atoms";
import { FormRoute } from "../../shared/routing/routes.config";
import { YourRenewalState } from "../YourRenewal/types";
import CreditCardAuthorisation from "./components/CreditCardAuthorisation";
import DirectDebitOptIn from "./components/DirectDebitOptIn";
import { PaymentDayNotification } from "./components/PaymentDayNotification";
import PaymentDayPicker from "./components/PaymentDayPicker";
import ViewPolicyDetails from "./components/ViewPolicyDetails";
import usePolicyDetails from "./hooks/usePolicyDetails";
import {
  FORM_NAME_DIRECT_DEBIT_OPT_IN,
  FORM_NAME_PAY_LATER_OPTION,
  FORM_NAME_PREFERRED_COLLECTION_DAY,
  PaymentDetailsFormProps,
  PaymentDetailsState,
} from "./types";

export const PaymentDetailsForm: React.FC<PaymentDetailsFormProps> = ({
  form,
  onSubmit,
  isRealtimePayment,
  openWestpacRetryDialog,
  onWestpacRetryTryAgainClick,
  onWestpacRetryDoneClick,
}) => {
  const { policyDetails } = usePolicyDetails();
  const ratingResponse = useRecoilValue(ratingResponseAtom) ?? getMockRatingResponse();
  const policyPaymentState = useRecoilValue(policyPaymentStateAtom);
  const yourPremium = useGetSessionState<YourRenewalState>(FormRoute.YourRenewal);

  const getDefaultPreferredCollectionDay = (): number | undefined => {
    if (form.getValues(FORM_NAME_PREFERRED_COLLECTION_DAY) !== undefined) {
      return form.getValues(FORM_NAME_PREFERRED_COLLECTION_DAY);
    }
    if (policyDetails) {
      return policyDetails.preferredCollectionDay || dateFromDayMonthYearString(policyDetails.startDate!).getDate();
    }
    return undefined;
  };

  const paymentDetails = useWatch({ control: form.control }) as PaymentDetailsState;
  const directDebitOptedIn = useWatch({ control: form.control, name: FORM_NAME_DIRECT_DEBIT_OPT_IN }) ?? false;
  const isAddingAccount = useWatch({ control: form.control, name: FORM_NAME_IS_ADDING_ACCOUNT });
  const existingAccount = useWatch({ control: form.control, name: FORM_NAME_EXISTING_PAYMENT_ACCOUNT });
  const payLaterOption = useWatch({ control: form.control, name: FORM_NAME_PAY_LATER_OPTION });
  const preferredCollectionDay = useWatch({
    control: form.control,
    name: FORM_NAME_PREFERRED_COLLECTION_DAY,
    defaultValue: getDefaultPreferredCollectionDay(),
  });

  const paymentFrequency = (yourPremium.frequency as PaymentFrequency) ?? PaymentFrequency.Annual;
  const isAnnualCash = paymentFrequency === PaymentFrequency.Annual;
  const shouldShowPayLater =
    (isAnnualCash && directDebitOptedIn === false) || (isAnnualCash && policyPaymentState?.isPaymentMethodLocked);
  const shouldShowPaymentSelection = (isAnnualCash && directDebitOptedIn === true) || !isAnnualCash;
  const shouldShowEmail = shouldShowPayLater || shouldShowPaymentSelection;

  useEffect(() => {
    if (isAddingAccount || existingAccount) {
      form.setValue(FORM_NAME_PAY_LATER_OPTION, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingAccount, existingAccount]);

  useEffect(() => {
    if (payLaterOption) {
      form.setValue(FORM_NAME_IS_ADDING_ACCOUNT, false);
      form.setValue(FORM_NAME_EXISTING_PAYMENT_ACCOUNT, "");
      form.setValue(FORM_NAME_EXISTING_PAYMENT_ACCOUNT_TYPE, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLaterOption]);

  const onFormInvalid = (errors: FieldErrors<PaymentDetailsState>) => {
    if (errors?.hasAcceptedTermsDirectDebit || errors?.hasAcceptedTermsRealtime) {
      gtm(event("Please read and agree to the terms to continue"));
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onFormInvalid)} action="#">
        {policyDetails && ratingResponse && (
          <Grid container>
            <RacwaStepperPageTitle title="Pay your renewal" />
            <Grid item xs={12}>
              <ViewPolicyDetails
                policyDetails={policyDetails}
                ratingResponse={ratingResponse}
                paymentFrequency={yourPremium.frequency ?? PaymentFrequency.Annual}
              />
            </Grid>

            {paymentFrequency === PaymentFrequency.Monthly && preferredCollectionDay && (
              <Grid item xs={12}>
                <Box mt={4}>
                  <PaymentDayPicker defaultValue={preferredCollectionDay} />
                </Box>
                <Box mt={2}>
                  <PaymentDayNotification
                    policyDetails={policyDetails}
                    ratingResponse={ratingResponse}
                    preferredCollectionDay={preferredCollectionDay}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box mt={3} mb={3}>
                <Divider />
              </Box>
            </Grid>

            {isAnnualCash && (
              <Grid item xs={12}>
                <Box mb={directDebitOptedIn === undefined ? 0 : 3}>
                  <DirectDebitOptIn isPaymentMethodLocked={policyPaymentState?.isPaymentMethodLocked} />
                </Box>
              </Grid>
            )}

            {shouldShowPaymentSelection && (
              <Grid item xs={12}>
                <PaymentSelection paymentFrequency={paymentFrequency} />
              </Grid>
            )}

            {shouldShowPayLater && (
              <Grid item xs={12}>
                <AnnualPaymentOptionsSelection
                  startDate={policyDetails.startDate ?? ""}
                  isPaymentMethodLocked={policyPaymentState?.isPaymentMethodLocked}
                />
              </Grid>
            )}

            {shouldShowEmail && (
              <Grid item xs={12}>
                <Box mt={3}>
                  <ConfirmEmail
                    defaultEmail={policyDetails.email ?? ""}
                    disabled={policyPaymentState?.isPaymentMethodLocked}
                  />
                </Box>
              </Grid>
            )}

            <CreditCardAuthorisation
              // Use form.getValues() as default in case the paymentDetails hasn't been set yet (since it's watching for form changes)
              paymentDetails={isEmptyObject(paymentDetails) ? form.getValues() : paymentDetails}
              policyPaymentState={policyPaymentState}
            />

            <Grid item xs={12}>
              <Box mt={5}>
                <Button type="submit" id="confirm" data-testid="confirm" color="primary" variant="contained" fullWidth>
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
        {getRenewalEndorsementRetryDialog(
          isRealtimePayment,
          openWestpacRetryDialog,
          onWestpacRetryTryAgainClick,
          onWestpacRetryDoneClick,
        )}
      </form>
    </FormProvider>
  );
};

export default PaymentDetailsForm;
