import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { colors } from "@racwa/styles";
import {
  AnnualPremium,
  BasicTooltip,
  MonthlyPremium,
  PaymentFrequency,
  QuotePremium,
  ToggleButtonGroup,
  ToggleButtonOption,
  isEmptyObject,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";

import {
  FORM_NAME_SELECTED_AGREED_VALUE,
  FORM_NAME_SELECTED_AGREED_VALUE_CHANGED,
  FORM_NAME_SELECTED_ANNEXE_COVER,
  FORM_NAME_SELECTED_CONTENTS_COVER,
  FORM_NAME_SELECTED_EXCESS,
  FORM_NAME_SELECTED_FREQUENCY,
  YourRenewalFormProps,
} from "./types";

import { RacwaStepperPageTitle } from "@racwa/react-components";
import { Excesses, RenewalRatingRequest } from "raci-policy-renewal-caravan-clientproxy";
import usePolicyDetails from "../Payment/hooks/usePolicyDetails";
import AgreedValue, { getAgreedValue } from "./components/AgreedValue";
import { AnnexeCover } from "./components/AnnexeCover";
import { ContentsCover } from "./components/ContentsCover";
import useRating from "./hooks/useRating";

/**
 * Determines if Excess Options are available.
 * Shield does not return Excess Options for a policy in the GET policy response.
 * Excess Options are only returned in Shield Rating response.
 *
 * TODO - ISE-7585 - Ticket has been raised with Shield to add Excess options to Shield API GET Policy response.
 * @param excesses
 * @returns boolean indicating that the Excess Options are available
 */
export const excessOptionsAvailable = (excesses: Excesses | undefined) =>
  excesses?.options! && excesses.options.length > 0;

export const Form = ({ form, policyNumber, onSubmit, onChangeValues }: YourRenewalFormProps) => {
  const { handleSubmit } = form;
  const renewalPrefix = "motor-renewal";
  const renewalPremiumTitleId = `${renewalPrefix}-premium-title`;

  const [ratingRequest, setRatingRequest] = useState<RenewalRatingRequest | undefined>(undefined);

  // TODO - SPK-4687 - Use policy hook or policy atom or state?
  const { policyDetails, isError: hasPolicyDetailsError } = usePolicyDetails(policyNumber);

  // const [yourRenewalState, setYourRenewalState] = useSessionState<YourRenewalState>();

  const { ratingResponse, yourRenewalState, setYourRenewalState } = useRating(
    policyDetails?.isRenewal === true ? ratingRequest : undefined,
  );

  const getDefaultPaymentFrequency = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.frequency
      ? yourRenewalState.frequency
      : policyDetails?.frequency ?? PaymentFrequency.Annual;
  const paymentFrequency = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_FREQUENCY,
    defaultValue: getDefaultPaymentFrequency() as PaymentFrequency,
  });

  const getDefaultExcess = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.excess && yourRenewalState.excess > -1
      ? yourRenewalState.excess
      : policyDetails?.excesses?.current;
  const excess = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_EXCESS,
    defaultValue: getDefaultExcess(),
  });

  const getDefaultAgreedValueChanged = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.agreedValueChanged
      ? yourRenewalState.agreedValueChanged
      : form.getValues().agreedValueChanged;
  const agreedValueChanged = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_AGREED_VALUE_CHANGED,
    defaultValue: getDefaultAgreedValueChanged(),
  });

  const getDefaultAgreedValue = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.agreedValue !== undefined
      ? yourRenewalState.agreedValue
      : policyDetails?.agreedValue?.current;
  const agreedValue = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_AGREED_VALUE,
    defaultValue: getDefaultAgreedValue(),
  });

  const getDefaultContentsCover = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.contentsValue !== undefined
      ? yourRenewalState.contentsValue
      : policyDetails?.covers?.find((cover) => cover.coverType === "AOCO")?.sumInsured;
  const contentsValue = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_CONTENTS_COVER,
    defaultValue: getDefaultContentsCover(),
  });

  const getDefaultAnnexeCover = () =>
    !isEmptyObject(yourRenewalState) && yourRenewalState.annexeValue !== undefined
      ? yourRenewalState.annexeValue
      : policyDetails?.covers?.find((cover) => cover.coverType === "AANO")?.sumInsured;
  const annexeValue = useWatch({
    control: form.control,
    name: FORM_NAME_SELECTED_ANNEXE_COVER,
    defaultValue: getDefaultAnnexeCover(),
  });

  /**
   * Unable to use form.formState.errors.agreedValue here as is not updated by the time the rating request is
   * validated which results in shield rejecting rating request due to irregularities relating to agreed value.
   */
  const isAgreedValueValid = !form.getFieldState(FORM_NAME_SELECTED_AGREED_VALUE).invalid;

  // Set PaymentFrequency in session state when form value changes for Renewal
  // Set the PaymentFrequency form value for MidTerm as the PaymentFrequency field/input is not available for Midterm policies
  useEffect(() => {
    if (paymentFrequency) {
      if (policyDetails?.isMidterm === true) {
        form.setValue(FORM_NAME_SELECTED_FREQUENCY, paymentFrequency);
      }

      setYourRenewalState({
        ...yourRenewalState,
        frequency: paymentFrequency,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFrequency]);

  // Set AgreedValueChanged and isAgreedValueValid in session state when Agreed Value form value or field state changes
  useEffect(() => {
    setYourRenewalState({
      ...yourRenewalState,
      agreedValueChanged,
      isAgreedValueValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreedValueChanged, isAgreedValueValid]);

  // Update rating request if any rating factors change
  useEffect(() => {
    setRatingRequest({
      policyNumber,
      excess: excess ?? getDefaultExcess(),
      agreedValue: getAgreedValue(
        agreedValueChanged ?? form.getValues().agreedValueChanged,
        agreedValue ?? form.getValues().agreedValue,
      ),
      contentsValue: contentsValue ?? form.getValues().contentsValue,
      annexeValue: annexeValue ?? form.getValues().annexeValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreedValueChanged, agreedValue, excess, contentsValue, annexeValue]);

  const renewalExcesses =
    excessOptionsAvailable(ratingResponse?.excesses) === true ? ratingResponse?.excesses : undefined;

  const showPremium = () => {
    if (policyDetails !== undefined) {
      if (policyDetails.isRenewal && ratingResponse?.annualPremium && ratingResponse?.monthlyPremium) {
        return (
          <QuotePremium
            id={renewalPrefix}
            name={FORM_NAME_SELECTED_FREQUENCY}
            isRenewalFlow
            premiumDetails={{
              annual: ratingResponse.annualPremium as AnnualPremium,
              monthly: ratingResponse.monthlyPremium as MonthlyPremium,
            }}
            defaultFrequency={(yourRenewalState?.frequency ?? policyDetails.frequency) as PaymentFrequency}
            breakdownType="premium"
            quoteHeading={
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h3" id={renewalPremiumTitleId} data-testid={renewalPremiumTitleId}>
                    Your renewal amount
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        );
      }
    }
  };

  const createNumberArray = (num: Number) => {
    const result = [];
    for (let i = 5; i > 0; i--) {
      result.push(Number(num) - i * 100);
    }
    for (let i = 0; i < 5; i++) {
      result.push(Number(num) + i * 100);
    }
    return result;
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        {policyDetails && !hasPolicyDetailsError && ratingResponse && (
          <Grid container>
            <RacwaStepperPageTitle title={"Here's your renewal"} />
            <Grid item xs={12} mt={2}>
              <Card variant="outlined">
                {showPremium()}
                {renewalExcesses && (
                  <>
                    <CardContent sx={{ backgroundColor: colors.racGrayLight }}>
                      <Typography variant="h3" sx={{ paddingTop: "8px", paddingBottom: "16px" }}>
                        Adjust the amount you pay
                      </Typography>

                      <Grid item xs={12}>
                        <hr />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container sx={{ alignItems: "center" }}>
                          <Grid item xs={8}>
                            <Typography variant="body1">
                              <b>Basic excess</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sx={{ display: "flex" }} alignItems="center">
                            <Box mr={1}>
                              <BasicTooltip
                                title="Excess"
                                id="excessTooltip"
                                message={
                                  <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                    The excess is the amount you may need to pay towards settlement of any claim.
                                    <p>
                                      An additional age excess applies to drivers and policyholders under 26 years of
                                      age.
                                    </p>
                                    <p>
                                      Individual under 19 years of age $650 Individual aged 19-20 years $550 Individual
                                      aged 21-23 years $450 Individual aged 24-25 years $300
                                    </p>
                                    For more details, see our PED Guide.
                                  </Typography>
                                }
                                showDialog={false}
                              />
                            </Box>
                            <ToggleButtonGroup
                              id="excess"
                              name="excess"
                              options={
                                renewalExcesses.options?.map((excess) => ({
                                  text: `$${excess}`,
                                  value: excess,
                                })) as ToggleButtonOption[]
                              }
                              onChange={(_, value) => {
                                onChangeValues({
                                  excess: value as number,
                                  agreedValueChanged: agreedValueChanged ?? false,
                                  isAgreedValueValid: isAgreedValueValid ?? false,
                                  ratingResponse: ratingResponse,
                                });

                                return;
                              }}
                              defaultValue={renewalExcesses.current}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <AgreedValue
                        current={yourRenewalState.agreedValue ?? policyDetails.agreedValue?.current!}
                        min={policyDetails.agreedValue?.min ?? 0}
                        max={policyDetails.agreedValue?.max ?? 1000}
                        onChangeValues={onChangeValues}
                      />
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <Grid container sx={{ alignItems: "center" }}>
                        <ContentsCover
                          current={yourRenewalState.contentsValue ?? policyDetails.contentsValue?.current ?? 1000}
                          coverOptions={createNumberArray(
                            yourRenewalState.contentsValue ?? policyDetails.contentsValue?.current ?? 1000,
                          )}
                          onChangeValues={onChangeValues}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <AnnexeCover
                        current={yourRenewalState.annexeValue ?? policyDetails.annexeValue?.current ?? 1000}
                        coverOptions={createNumberArray(
                          yourRenewalState.annexeValue ?? policyDetails.annexeValue?.current ?? 1000,
                        )}
                        onChangeValues={onChangeValues}
                      />
                    </CardContent>
                    <Grid item xs={12}>
                      <Card sx={{ backgroundColor: colors.white, paddingX: "8px", paddingY: "24px" }}>
                        <Button type="submit" data-testid="submit" color="primary" variant="contained" fullWidth>
                          Next
                        </Button>
                      </Card>
                    </Grid>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
