export const NON_BREAKING_SPACE = "\u00a0";

export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";

// <------------------>
//  Storage Keys
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_PRC_";
export const STORAGE_KEY_POLICY_NUMBER = `${SESSION_KEY_PREFIX}POLICY_NUMBER`;
export const YOUR_PREMIUM_SESSION_STATE_KEY = "YourRenewal";
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;
export const POLICY_DETAILS_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}PolicyDetails`;
export const CONFIRMATION_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Confirmation`;
export const PAYMENT_DETAILS_SESSION_STATE_KEY = "PaymentDetails";

// <------------------>
//  ADB2C
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
export const ADB2C_RETURN_URL = process.env.REACT_APP_ADB2C_RETURN_URL ?? "";
export const PRODUCT_LINKING_REDIRECT_URL = process.env.REACT_APP_PRODUCT_LINKING_REDIRECT_URL ?? "";
export const SILENT_LOGIN_REDIRECT_URL = `${process.env.PUBLIC_URL}/redirect`;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = "AtomSessionId";
export const ATOM_POLICY_PAYMENT_STATE = `${SESSION_KEY_PREFIX}AtomPolicyPaymentState`;
export const ATOM_SHOW_WESTPAC_RETRY_DIALOG = `${SESSION_KEY_PREFIX}AtomShowWestpacRetryDialog`;
export const ATOM_RATING = `${SESSION_KEY_PREFIX}AtomRating`;
export const ATOM_POLICY_DETAILS = `${SESSION_KEY_PREFIX}AtomPolicyDetails`;
export const ATOM_FORM_COMPLETED = `${SESSION_KEY_PREFIX}AtomFormCompleted`;
export const ATOM_ENDORSEMENT = `${SESSION_KEY_PREFIX}AtomEndorsement`;
export const ATOM_PREVIOUS_RATING_REQUEST = `${SESSION_KEY_PREFIX}AtomPreviousRatingRequest`;
export const ATOM_ROUTE_STATE = `${SESSION_KEY_PREFIX}AtomRouteState`;
