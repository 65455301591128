import { useGetPreviousPageSessionState, useSessionStateInitialiser } from "raci-react-library";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getB2CPCMUrl from "../../utils/getB2CPCMUrl";
import { RouteInformation, allRoutes, errorRoutes, formRoutes, preFormRoutes } from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  preFormRoutes: RouteInformation[];
  formRoutes: RouteInformation[];
  errorRoutes: RouteInformation[];
  steps: StepInformation[];
  activeStepIndex?: number;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialiseSessionStates = useSessionStateInitialiser();

  useEffect(() => {
    initialiseSessionStates(
      allRoutes.map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const { path: previousPageUrl } = useGetPreviousPageSessionState();

  const isActiveFlow = formRoutes.filter((item) => item.path === location.pathname).length > 0;

  const steps: StepInformation[] = formRoutes;

  const activeStepIndex = isActiveFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;

  const navigateToPreviousStep = useMemo(() => {
    if (!isActiveFlow) {
      return undefined;
    }

    return previousPageUrl && activeStepIndex !== undefined && activeStepIndex > 0
      ? () => navigate(-1)
      : () => (window.location.href = getB2CPCMUrl("")); // TODO: Policy number here
  }, [isActiveFlow, previousPageUrl, activeStepIndex, navigate]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep && steps.indexOf(formRoutes[formRoutes.length - 1]) !== activeStepIndex;

  return {
    preFormRoutes,
    formRoutes,
    errorRoutes,
    steps,
    activeStepIndex,
    canNavigateToPreviousStep,
    navigateToPreviousStep,
  };
};

export default useRoutes;
